<template>
  <div :class="className">
    <el-dropdown
      class="configTRoot"
      trigger="click"
      @command="save"
      @visible-change="getIPPorts"
      placement="bottom-start">
      <el-button
        type="success"
        v-if="btnFlag">Config T</el-button>
      <span
        v-else
        class="el-dropdown-link">
        <i class="el-icon-setting el-icon--left green"></i><span class="config-T">{{$t('lang.Setting')}}</span>
      </span>
      <el-dropdown-menu
        class="packIPSelect"
        :class="{'transparent': ipList.length <= 1 }"
        slot="dropdown"
        v-loading='loadingIP'>
        <el-dropdown-item
          :title="`${item.ip} ${item.cops ? item.cops : ''}`"
          v-for="(item, index) in ipList"
          :key="index"
          :command="item.ip">
          {{item.name}}
          <span
            v-if="item.ip!='auto'"
            style="color: #888;font-weight:200;">
            ({{ `${item.ip} ${item.cops ? item.cops : ''}`}})
          </span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <configTBox
      v-if="configTObj.show"
      :itemInfo="itemInfo"
      :configTObj="configTObj"></configTBox>
  </div>
</template>
<script>
import configTBox from '../ConfigT/configTBox'
export default {
  props: {
    bindPeerId: String,
    btnFlag: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: 'left'
    }
  },
  data () {
    return {
      ipList: [{ ip: 'auto', name: 'Auto' }],
      loadingIP: false,
      configTObj: {
        show: false,
        bindPeerId: '',
        ip: '',
        showIP: false
      }
    }
  },
  components: {
    configTBox
  },
  methods: {
    getIPPorts (flag) {
      if (!flag) return
      this.loadingIP = true
      this.ipList = [{ ip: 'auto', name: 'Auto' }]
      this.axios.post('/ccp/tvucc-device/device/listLocalIp', { peerId: this.bindPeerId }).then(res => {
        this.loadingIP = false
        if (res.data.errorCode == '0x0') {
          const arr = res.data.result
          if (arr.length <= 0) {
            this.save()
            return
          }
          arr.forEach(item => {
            if (item.ip && item.ip != '0.0.0.0') {
              item.name = item.slotId == 0 ? 'E' : `Slot ${item.slotId}`
              this.ipList.push(item)
            }
          })
          if (this.ipList.length == 1 && this.ipList[0].ip == 'auto') this.save()
        } else {
          this.save()
        }
      }).catch(() => {
        this.loadingIP = false
        this.save()
      })
    },
    save (ip) {
      if (!ip || ip == 'auto') ip = ''
      this.configTObj = {
        show: true,
        bindPeerId: this.bindPeerId,
        ip: ip,
        showIP: true
      }
    }
  }
}
</script>
<style lang="less" scoped>
.configTRoot {
  width: fit-content;
  float: left;
  .green {
    color: #33aa50;
    font-size: 20px;
    cursor: pointer;
  }
  .config-T {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    color: #dbdbdb;
  }
}
.el-dialog__wrapper {
  ::v-deep .el-dialog {
    height: 700px;
    .el-dialog__body {
      height: calc(100% - 50px);
    }
  }
}
</style>
