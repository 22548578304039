<template>
  <el-dropdown
    class="qualityDropDown"
    :class="{'mediumBox': type == 'medium'}"
    :hide-on-click="true"
    @command="changeQuality"
    trigger="click"
    placement="bottom-start"
    @visible-change="visibleChange">
    <span class="el-dropdown-link">
      {{selectVal == 0? 'Auto' : selectVal}}<i
        ref="btnDom"
        :class="btnClass"></i>
    </span>
    <el-dropdown-menu
      class="qualityMenuSelect"
      slot="dropdown">
      <el-dropdown-item
        v-for="(item, index) in quality"
        :class="{greenBox:selectVal == item.value}"
        :key="index"
        :command="item">{{ item.label}}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script type="text/javascript">
import { speedList } from '@/config.js'
export default {
  props: {
    selectTotal: {
      type: [String, Number],
      default: '6Mb/s'
    },
    type: {
      type: String,
      default: 'mini'
    }
  },
  data () {
    return {
      quality: speedList,
      selectVal: '6Mb/s',
      btnClass: 'el-icon-arrow-top'
    }
  },
  created () {
    this.selectVal = this.selectTotal
  },
  watch: {
    selectTotal: function (val) {
      this.selectVal = val
    }
  },
  methods: {
    visibleChange (flag) {
      this.btnClass = flag ? 'el-icon-arrow-up' : 'el-icon-arrow-top'
      if (flag) this.selectVal = this.selectTotal
    },
    changeQuality (item) {
      this.selectVal = item.value
      this.$emit('changeQuality', { data: item.value, key: item.key })
    }
  }
}
</script>
