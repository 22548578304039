<template>
  <div class="clearfix infoBox">
    <!-- 视频音量模块 -->
    <div class="voiceModule left m_r_25">
      <voice
        :pageRWebsocket="pageRWebsocket"
        :source="sourceItem"
        :rid="rid"></voice>
    </div>
    <!-- 视频模块 -->
    <div class="vidoeModule left m_r_25">
      <video-module
        :height="videoHeight"
        :width="videoWidth"
        :source="sourceItem"
        :deviceType="deviceType"
        :rid="rid"
        :sid="sourceItem.peerId"
        :delayVal="delayVal"
        :status="sourceItem.status" />
    </div>
    <!-- 比特率延迟模块 -->
    <div class="delayBitrateModule left m_r_25">
      <bitrate-delay
        :sourceItem="sourceItem"
        width="3.57rem"
        :rid="rid"
        :checkSourceId="checkSourceIdFlag"
        :pn="pn"></bitrate-delay>
    </div>
    <!-- detail信息模块 -->
    <div class="DetailModule left m_r_25">
      <live-info
        :pageRWebsocket="pageRWebsocket"
        :source="sourceItem"
        :rid="rid"></live-info>
    </div>
  </div>
</template>
<script>
import Bus from '@/assets/js/vueBus'
import { mapState } from 'vuex'
import videoModule from '@/components/VideoC'
import bitrateDelay from '@/views/receivercontrol/setting'
import Voice from '@/views/MySource/voice'
import LiveInfo from '@/views/MySource/liveInfo'

export default {
  data () {
    return {
      rid: null,
      init: true,
      sendWebsocket: false,
      sourceItem: {},
      videoWidth: '752px',
      videoHeight: '423px',
      delayVal: 0.5
    }
  },
  props: {
    deviceType: {
      type: String,
      default: 'T'
    },
    checkSourceIdFlag: {
      type: Boolean,
      default: false
    },
    pn: {
      type: String,
      default: 'hm'
    }
  },
  components: {
    videoModule,
    bitrateDelay,
    Voice,
    LiveInfo
  },
  watch: {
    peerId: function (newId, oldId) {
      if (newId && oldId && this.deviceType === 'T') {
        this.State.ReceiverInfoObj = {}
        this.State.GetKernelStateObj = {}
        Bus.$emit('selectOtherR')
        Bus.$emit('clearVideo')
      }
    },
    rid: function (newId, oldId) {
      if (oldId != null) {
        this.State.GetKernelStateObj = {}
        const isMyReceiverPage = window.location.href.includes('/mr')
        if (isMyReceiverPage) {
          Bus.$emit('selectOtherR', '', true)
        } else {
          Bus.$emit('selectOtherR')
        }
      }
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      pageRWebsocket: state => state.websocket.pageRWebsocket
    }),
    peerId: function () {
      return this.sourceItem.peerId
    }
  },
  created () {
    window.addEventListener('resize', this.initVideoSize)
    this.initVideoSize()
    Bus.$off('showReceiverInfo')
    Bus.$on('showReceiverInfo', dataObj => {
      if (dataObj.isDown) {
        this.State.drawDownSource = dataObj.sourceItem
        this.sourceItem = dataObj.sourceItem || {}
        // source在live的时候用live的Rid去查询数据
        if (dataObj.sourceItem.status == '2') {
          // 根据传入的deviceType类型去判断是Home页面还是myReceiver页面
          if (this.deviceType === 'R') {
            this.rid = dataObj.rid
          } else {
            if (
              this.sourceItem.gridLivePeerId != null &&
              this.sourceItem.gridLivePeerId.includes(dataObj.rid)
            ) {
              this.rid = dataObj.rid
            } else {
              if (
                this.sourceItem.type != 'Ext' &&
                this.sourceItem.type != 'YouTube' &&
                this.sourceItem.type != 'X'
              ) {
                console.info('TInfo', this.sourceItem)
                this.rid = this.sourceItem.livePeerId
              }
            }
          }
        } else {
          this.rid = dataObj.rid
        }
      } else {
        this.State.drawDownSource = {}
      }
      if (this.rid) {
        if (this.deviceType === 'R') {
          this.sendMessage()
        } else {
          if (dataObj.sourceItem.status == '2') {
            /*
             * 只有在当前source的livePeerId和当前的rid相同的时候采取更新里面的内容
             * if(dataObj.sourceItem.livePeerId.includes(dataObj.rid)) {
             */
            this.sendMessage()
            // }
          } else {
            // 当前source不在live时根据R的状态去清除视频
            if (this.State.GetKernelStateObj.CurrentTStrIdHex) {
              if (
                this.State.GetKernelStateObj.CurrentTStrIdHex ==
                this.sourceItem.peerId.toUpperCase()
              ) {
                this.sendMessage()
              }
            } else {
              this.State.ReceiverInfoObj = {}
              this.State.GetKernelStateObj = {}
              this.State.SettingObj = {
                CurrentSetting: { Name: 'Internal_Live' },
                ModeList: []
              }
              Bus.$emit('selectOtherR')
            }
          }
        }
      }
    })
    Bus.$on('delayVal', val => {
      this.delayVal = val
    })
  },
  mounted () {
    // 展开或者切换T时的通知
    Bus.$on('restoreDefaultConfig', () => {
      this.sourceItem = {}
    })
  },
  methods: {
    initVideoSize () {
      if (document.body.clientWidth >= 1280) {
        this.videoWidth = '752px'
        this.videoHeight = '423px'
      } else if (document.body.clientWidth >= 760) {
        this.videoWidth = '528px'
        this.videoHeight = '297px'
      } else {
        this.videoWidth = '364px'
        this.videoHeight = '204.7px'
      }
    },
    // create websocket to get R info
    sendMessage () {
      /*
       * this.getReceiverInfo();
       * this.getQueryKennel();
       * this.getSettingInfo();
       */
    },
    // 获取R版本等信息接口
    getReceiverInfo () {
      this.pageRWebsocket.send({
        message: {
          rid: this.rid,
          categoryId: '2152857600',
          operationType: '101'
        },
        time: 1000,
        isOnce: true,
        key: 'getReceiverInfo',
        success: res => {
          const data = res.data ? JSON.parse(res.data) : {}
          this.State.ReceiverInfoObj = data
          // 两种情况1.在其他source上选择当前的R则需要清空数据2.当前source没在live不需要清空数据
          if (
            this.sourceItem.status != '2' &&
            this.State.GetKernelStateObj.CurrentTStrIdHex !=
            this.sourceItem.peerId.toUpperCase()
          ) { this.State.ReceiverInfoObj = {} }
        }
      })
    },
    // get bitrate,delay and quality info
    getSettingInfo () {
      this.pageRWebsocket.send({
        message: {
          rid: this.rid,
          categoryId: '2152866048',
          operationType: '101'
        },
        time: 1000,
        isOnce: true,
        key: 'getSettingInfo',
        success: res => {
          const data = res.data ? JSON.parse(res.data) : {}
          this.State.SettingObj = data
          Bus.$emit('getSetting', data)
          /*
           * console.log("liveTId: " + this.State.GetKernelStateObj.CurrentTStrIdHex);
           * console.log("currentTId: " + this.sourceItem.peerId.toUpperCase());
           */
          if (
            this.sourceItem.status != '2' &&
            this.State.GetKernelStateObj.CurrentTStrIdHex != this.sourceItem.peerId.toUpperCase()) { this.State.SettingObj = {} }
        }
      })
    },
    // get play video url and other info
    getQueryKennel () {
      this.pageRWebsocket.send({
        message: {
          rid: this.rid,
          categoryId: '2152857600',
          operationType: '102'
        },
        time: 1000,
        isOnce: true,
        key: 'getQueryKennel',
        success: res => {
          const data = res.data ? JSON.parse(res.data) : {}
          this.State.GetKernelStateObj = data
          if (
            this.sourceItem.status != '2' &&
            this.State.GetKernelStateObj.CurrentTStrIdHex !=
            this.sourceItem.peerId.toUpperCase()
          ) { this.State.GetKernelStateObj = {} }
        }
      })
    }
  },
  beforeDestroy () {
    Bus.$off('restoreDefaultConfig')
    window.removeEventListener('resize', this.initVideoSize)
    if (this.pageRWebsocket) {
      this.$createWebsocket.pageRWebsocket('stop', 'getReceiverInfo')
      this.$createWebsocket.pageRWebsocket('stop', 'getSettingInfo')
      this.$createWebsocket.pageRWebsocket('stop', 'getQueryKennel')
    }
  }
}
</script>
<style lang="less" scoped>
.infoBox {
  padding: 24px 30px;
  .delayBitrateModule {
    width: 28%;
  }
  .voiceModule {
    height: 423px;
  }
}
@media (max-width: 2500px) {
  .DetailModule {
    clear: both;
  }
}
@media (max-width: 1919px) {
  .DetailModule {
    width: 90%;
    margin-left: 0;
  }
}
@media (max-width: 1599px) {
  .infoBox .delayBitrateModule {
    margin-top: 24px;
    width: 40%;
  }
  .DetailModule {
    width: 40%;
    margin-left: 5%;
  }
}
@media (max-width: 1279px) {
  .infoBox {
    padding-right: 0;
    .voiceModule {
      height: 297px;
      margin-right: 20px;
    }
    .DetailModule {
      width: 51%;
      margin-left: 10%;
      margin-right: 0;
    }
    .vidoeModule {
      margin-right: 0;
    }
  }
  .delayBitrateModule {
    margin-left: -35px;
  }
}
@media (max-width: 759px) {
  .infoBox {
    position: relative;
    font-size: 12px;
    .vidoeModule {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    .voiceModule {
      position: absolute;
      top: 215px;
      height: 204px;
    }
    .delayBitrateModule {
      width: 95%;
      position: absolute;
      top: 440px;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
    }
    .DetailModule {
      width: 70%;
      position: absolute;
      right: 5px;
      top: 215px;
      margin-left: 10px;
    }
  }
}
</style>
