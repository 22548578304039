<template>
  <div
    :class="[
      'receiverItem',
      showSizeClass == 'm' ? 'sourceItemM' : '',
      showSizeClass == 'l' ? 'sourceItemL' : '',
    ]">
    <div
      :class="[
        'thumbnail-view-img pr',
        showSizeClass == 'm' ? 'imgMShow' : '',
        showSizeClass == 'l' ? 'imgLShow' : '',
      ]"
      :title="receiverItemOps.peerId">
      <div
        v-if="socketItemData.status"
        @click="showVideo(receiverItemOps.peerId)"
        class="defaultBgImg">
        <img
          :src="freshImg"
          class="receiverImg"
          alt=""
          v-if="socketItemData.status == 2"
          :data-info="JSON.stringify({id:socketItemData.peerId, type: 'R'})" />
        <img
          :src="onlineImg"
          class="receiverImg"
          alt=""
          v-if="socketItemData.status == 1"
          :data-info="JSON.stringify({id:socketItemData.peerId, type: 'R'})" />
        <img
          :src="offlineImg"
          class="receiverImg offlineImg"
          alt=""
          v-if="socketItemData.status == 0"
          :data-info="JSON.stringify({id:socketItemData.peerId, type: 'R'})" />
      </div>
    </div>
    <div class="receiverItemName">
      <span>{{socketItemData.name}}</span>
    </div>
    <div class="switchSourcePeer">
      <el-input
        class="select-source"
        v-model="selectName"
        clearable
        @clear="clearDragInfo"
        :data-info="JSON.stringify({id:socketItemData.peerId, type: 'R'})"></el-input>
      <!--引入Take组件-->
      <videoButton
        :style="{ float: showSizeClass == 'm' || 'l' ? 'right' : 'left', width: '73px'}"
        :sourceItem="socketItemData"
        :hadSelectDevice="isSelectNewR"
        flag="R"
        :deviceList="deviceList"
        :key="receiverItemOps.peerId"
        @changeHadSelectDevice="changeHadSelectDevice">
      </videoButton>
    </div>

    <!--显示live Status-->
    <div
      class="t_thumbnail_view_item_icon liveBg"
      v-if="socketItemData && socketItemData.status == 2">
      {{ $t('lang.home_Live') }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import videoButton from '@/components/VideoButton'
import Bus from '@/assets/js/vueBus'
import { myReceiversAnalyze } from '@/assets/js/googleAnalyze/myReceiver.js'

export default {
  /*
   * receiverItemOps:通过Ajax传递过来的每条receiver的信息
   * socketItemDatas:通过websocket发送过来的每条receiver的socket动态信息
   * locationInfo需要传进来的经纬度信息
   *
   */
  props: {
    receiverItemOps: {
      type: Object,
      default: () => {
        return {}
      }
    },
    socketItemDatas: {
      type: Object,
      default: () => {
        return {}
      }
    },
    listKey: {
      type: Number,
      default: 0
    },
    locationInfo: {
      type: Array
    },
    imgUrlTime: Object,
    bookmarkObj: Object,
    locationShow: {
      default: true,
      type: Boolean
    },
    dragDomInfo: {
      type: Object,
      default: null
    }
  },
  name: 'receiverItem',
  components: {
    videoButton
  },
  data () {
    return {
      currentReceiverId: this.receiverItemOps.peerId,
      logo: 'this.src="' + require('@/assets/img/logo_thumbnail.png') + '"', // 如果图片报错使用默认图片,并解决图片闪烁问题
      deviceList: '', // 向Take按钮传送的值
      socketItemData: this.socketItemDatas, // 每一项的websocket中的值
      isFavorite: this.receiverItemOps.favorite == '1',
      enterName: this.$t('lang.rinputDescrible'),
      memo: this.receiverItemOps.describes, // 描述
      isCanFreshMemo: true, // 是否可以设置memo
      isShow: {
        deviceDetail: true,
        bookmark: false
      },
      isSelectNewR: false, // 是否重新选择了source
      isCanUpdateFavo: true, // 是否可以刷新favorite
      blobService: this.$store.state.common.baseUrl.blobUrl,
      deafultImg: require('@/assets/img/logo_thumbnail.png'),
      freshImg: '', // 要被刷新的图片的路径
      sItemId: this.receiverItemOps.livePeerId
        ? this.receiverItemOps.livePeerId.split(';')[0]
        : '', // 被选择的source的id
      onlineImg: require('@/assets/img/logo_thumbnail.png'),
      offlineImg: require('@/assets/img/offlinePng.png'),
      shareFlag: false,
      receiverId: this.socketItemDatas.peerId,
      sourceId: this.receiverItemOps.livePeerId
        ? this.receiverItemOps.livePeerId.split(';')[0]
        : '',
      selectRIsLive: this.socketItemDatas && this.socketItemDatas.status == '2',
      locationHref: '',
      locationInfoCu: {}, // 当前的位置信息
      showSource: [], // v-model的次联框的值
      showSelectInput: true, // 判断显示哪个输入框,
      showSourceInfo: '',
      updateFlag: true,
      arrFlag: true, // 用于搜索的数组操作
      parentLoad: true,
      selectGridId: '',
      selectGridName: '',
      selectName: ''
    }
  },
  mounted () {
    // selectGridName || socketItemData.livePeerName
    document.addEventListener('visibilitychange', this.getPageHide)
    this.$once('hook:mounted', () => {
      document.removeEventListener('click', this.clickEvent, true)
      document.addEventListener('click', this.clickEvent, true)
    })
    if (this.receiverItemOps.status == 2) {
      this.showSourceInfo = this.receiverItemOps.livePeerName
    }
  },
  created () {
    if (this.receiverItemOps.status == 2) {
      // 第一次将live的source Id 和Rid拼接
      const rid = this.receiverItemOps.peerId ? this.receiverItemOps.peerId : ''
      this.deviceList = this.receiverItemOps.livePeerId + ',' + rid
      this.selectName = this.receiverItemOps.livePeerName
    }
    if (this.receiverItemOps.status == 2) {
      this.freshImg = window.location.protocol + '//' + this.blobService + '/' + this.receiverItemOps.peerId + '?random=' + Math.random()
    } else {
      this.freshImg = this.deafultImg
    }

    setInterval(() => {
      if (this.imgUrlTime.current[this.socketItemData.peerId] == this.imgUrlTime.last[this.socketItemData.peerId]) return
      if (this.socketItemData.status == 2) {
        // 不是在live的不能获取缩略图
        let blobUrl = ''
        if (this.socketItemData.livePeerId) {
          // 缩略图:开始使用source的Id 10秒后使用R的id
          const isCompressThumbnail = JSON.parse(localStorage.getItem('newCCInitConfig')).urlInfo && JSON.parse(localStorage.getItem('newCCInitConfig')).urlInfo.isCompressThumbnail == '0'
          if (this.socketItemData.livePeerType == 'X') {
            blobUrl = window.location.protocol + '//' + this.blobService + '/' + this.socketItemData.livePeerId + '?'
            blobUrl = isCompressThumbnail ? `${blobUrl}type=original&app=cc&${Math.random()}` : `${blobUrl}random=${Math.random()}&app=cc`
          } else {
            blobUrl = window.location.protocol + '//' + this.blobService + '/' + this.socketItemData.peerId + '?'
            blobUrl = isCompressThumbnail ? `${blobUrl}type=original&app=cc&${Math.random()}` : `${blobUrl}random=${Math.random()}&app=cc`
          }
        } else {
          blobUrl = this.deafultImg
        }
        this.freshImg = blobUrl
      }
    }, 2000)
  },
  methods: {
    getPageHide (e) {
      if (e.target.visibilityState == 'visible') {
        this.showSelectInput = true
      }
    },
    // 打开或关闭bookmark box
    operationBookmarkBox (val) {
      this.isShow.bookmark = val
    },
    memoFocus () {
      myReceiversAnalyze('myReceiversMemoFocus', this.$route.path)
      this.isCanFreshMemo = false
    },
    changeHadSelectDevice (val) {
      /*
       * 获取是否重新选择了R
       * 此处接收两处地方的数据,一个是VideoButton是否操作完成的信息(start,stop)以便SelectSourceR组件来更新input里面的显示值
       * 一个是接收SelectSourceR里面的数据 以便VideoButton组件知道是否重新选择了R
       *
       */
      this.isSelectNewR = val
    },
    showVideo (rId) {
      // 点击设备出现 下拉视频的前期准备 (获取响应的数据)
      if (this.socketItemData.status == 0) return false
      if (!rId) return false
      if (this.socketItemData == undefined) return false
      if (this.socketItemData && this.socketItemData.status && this.socketItemData.status == 0) return false
      const data = {
        key: this.listKey,
        currentReceiverData: this.socketItemData,
        sourceId: this.sItemId, // 用以做前后对比 以便判断是否显示播放组件
        rid: rId
      }
      this.$emit('showVideo', data) // 将播放的R id或者默认的id 和其他信息发送给播放组件
      Bus.$emit('restoreDefaultConfig')
      Bus.$emit('clearVideo')
    },
    getSelectInfo (data) {
      if (this.shareFlag) this.sourceId = data.deviceList.split(',')[0]
      this.deviceList = data.deviceList // 监听SelectSourceR.vue组件传递过来的参数
      this.sourceId = data.deviceList.split(',')[0]
      this.selectRIsLive =
        this.socketItemDatas &&
        this.socketItemDatas.livePeerId != null &&
        this.socketItemDatas.livePeerId == this.sourceId
      this.$emit('getSelectInfoContent', data) // 将选择的当前R向上传递到sourceContent中,
    },
    gotoMap (url) {
      // 跳转到map
      myReceiversAnalyze('myReceiverGotoMap', this.$route.path)
      window.open(url)
    },
    clickEvent (e) {
      const tar = e.srcElement || e.target
      try {
        if (!this.$refs.sourceSearch.contains(tar)) {
          this.showSelectInput = true
        }
      } catch (error) { }
    },
    clearDragInfo () {
      this.selectGridId = ''
      this.selectGridName = ''
      this.isSelectNewR = false
      Bus.$emit('hideStartFun')
    }
  },
  watch: {
    dragDomInfo: {
      handler (val) {
        if (val) {
          if (this.receiverItemOps.status == 2 || this.receiverItemOps.status == 1) {
            this.selectGridId = val.peerId
            this.selectGridName = val.name
            this.selectName = val.name
            this.isSelectNewR = true
            this.deviceList = this.selectGridId + ',' + this.receiverId
            return
          }
        }
        this.selectGridId = ''
        this.selectGridName = ''
        this.selectName = ''
        this.isSelectNewR = false
        this.deviceList = ''
      },
      immediate: true
    },
    receiverItemOps (newVal) {
      this.memo = newVal.describes
    },
    socketItemDatas (val) {
      this.socketItemData = val
      if (val.livePeerId != this.sItemId) {
        // 说明livePeerId变化了
        this.sItemId = val.livePeerId
        this.sourceId = val.livePeerId
        const getSelectInfo = {
          rid: this.socketItemData.peerId,
          deviceList: this.sItemId + ',' + this.socketItemData.peerId
        }
        this.$emit('getSelectInfoContent', getSelectInfo) // 如果livePeerId变化了 将livepeerId 传递过去 供video使用
        if (this.updateFlag) {
          if (val.status == 2) {
            this.showSourceInfo = val.livePeerName
          }
        }
      } else {
        if (val.status == 2 && !this.selectGridName) {
          this.selectName = val.livePeerName
        }
      }

      // 监听当前R状态，控制media的显示与隐藏
      this.selectRIsLive = val && val.livePeerId && val.livePeerId == this.sourceId

      if (!this.isSelectNewR) {
        // 如果没有重新选择
        if (val.status == 2) {
          // 如果正在live 将最新的R Id发送给Take组件
          this.deviceList = val.livePeerId + ',' + val.peerId
        }
      }
    },
    locationInfo (val) {
      if (val.length > 0) {
        val.map(item => {
          if (
            this.receiverItemOps.livePeerId &&
            item.peerId.toLowerCase().trim() == this.receiverItemOps.livePeerId.slice(0, 16).toLowerCase().trim()
          ) {
            if (typeof item.address == 'string') {
            } else {
              let address = ''
              if (item.address) {
                item.address.map((itm, inx) => {
                  if (inx != item.address.length - 1) {
                    address += itm.long_name + ' , '
                  } else {
                    address += itm.long_name
                  }
                })
              }
              item.address = address
            }

            this.locationInfoCu = Object.assign({}, item)
            if (item.location && item.location.trim() != 'null,null') {
              const lat = item.location.split(',')[0]
              const lng = item.location.split(',')[1]
              this.locationHref = encodeURI(`${location.protocol}//${localStorage.getItem('newCCUrl')}/mapPage.html#?lat=${lat}&lng=${lng}&name=${this.receiverItemOps.name}`) // 线上使用
            } else {
              this.locationHref = 'NA'
            }
          }
        })
      }
    }
  },
  computed: mapState({
    State: state => state,
    showSizeClass: state => state.common.showRecevierSize
  }),
  beforeRouteLeave (to, from, next) {
    // 销毁组件的时候 关闭监听 防止重复监听 浪费资源
    document.removeEventListener('click', this.clickEvent, true)
    next()
  },
  destroyed () {
    document.removeEventListener('visibilitychange', this.getPageHide)
  }
}
</script>

<style lang="less" scoped>
.red {
  color: red;
}

.show {
  opacity: 1 !important;
}

.check {
  display: inline-block;
  float: right;
  color: #39aa50;
  font-size: 18px;
  opacity: 0;
  font-weight: bold;
}

.white {
  color: #dbdbdb;
}

.brow {
  color: #777;
}

.locationSet {
  position: absolute;
  cursor: pointer;
  height: 25px;
  /*width: 178px;*/
  width: 100%;
  font-size: 0.1rem;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.86) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  line-height: 25px;
  z-index: 1;
  color: #dbdbdb;
  right: 0;
  top: 0;

  a {
    display: inline-block;
    width: 52%;
    height: 100%;
    margin-left: 48%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    /*background: linear-gradient(180deg, rgba(0, 0, 0, 0.86) 0%, rgba(0, 0, 0, 0) 100%);*/
  }

  span {
    display: inline-block;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  i {
    padding: 0 4px;
  }
}

.defaultBgImg {
  width: 100%;
  height: 100%;
  background: url('../../assets/img/logo_thumbnail.png') no-repeat center;
  background-size: 100%;
}

.defaultBgImg img {
  width: 100%;
  height: 100%;
}
.defaultBgImg .offlineImg {
  cursor: no-drop;
}
::v-deep .el-input .el-input__inner {
  border: none;
}

::v-deep .el-input.is-disabled .el-input__inner {
  background: #424242 !important;
}

::v-deep .el-input.is-disabled:hover {
  border: none;
}

::v-deep .el-button--info.is-plain.is-disabled,
::v-deep .el-button--info.is-plain.is-disabled:hover,
::v-deep .el-button--info.is-plain.is-disabled:focus,
::v-deep .el-button--info.is-plain.is-disabled:active {
  background: #676767;
}

::v-deep .el-image {
  width: 100%;
  height: 100%;
}

.receiverItem {
  width: 270px;
  height: 228px;
  background: #08130a;
  position: relative;
  margin: 0 auto;
  margin-bottom: 9px;
  /*float: left;*/

  .favorite {
    float: left;
    margin-right: 4px;

    i {
      font-size: 20px;
    }

    .android-favorite-outline {
      color: #ccc;
    }

    .android-favorite {
      color: #ff4d4f;
    }
  }

  .pairedImg {
    width: 16px;
    height: 16px;
    margin-top: 15.5px;
    margin-right: 5px;
    margin-left: 10px;
  }

  .left {
    float: left;
  }

  .thumbnail-view-img {
    height: 151px;
    width: 100%;
    cursor: pointer;
    border: none;
    overflow: hidden;
    img {
      width: 100% !important;
      border: none;
      z-index: 1;
    }

    .extTip {
      position: absolute;
      top: 14px;
      left: 48%;
      width: 40%;
      margin-left: -20%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: white;
      background: rgba(1, 1, 1, 0.4);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      border-radius: 4px;
      z-index: 2;
    }
  }

  .extBg {
    height: 205px;
    width: 100%;
    // background: url("../../assets/img/ext.jpg") no-repeat center;
    background: url('../../assets/img/External.png') no-repeat center;
    background-size: 100%;
  }

  .youtubeBg {
    height: 205px;
    width: 100%;
    // background: url("../../assets/img/youtube.jpg") no-repeat center;
    background: url('../../assets/img/YouTube_2.png') no-repeat center;
    background-size: 100%;
  }

  .packBg {
    height: 205px;
    width: 100%;
    background: url('../../assets/img/logo_thumbnail.png') no-repeat center;
    background-size: 100%;
  }

  .SMPTE2022Bg {
    height: 205px;
    width: 100%;
    background: url('../../assets/img/SMPTE.png') no-repeat center;
    background-size: 100%;
  }

  .t_thumbnail_view_item_icon {
    position: absolute;
    top: 0.16rem;
    left: 16px;
    width: 52px;
    height: 24px;
    border: none;
    background: #1ec345;
    border-radius: 2px;
    line-height: 24px;
    text-align: center;
  }

  .receiverItemName {
    /*margin-left: 1%;*/
    height: 22px;
    text-align: left;
    padding: 0 10px;
    line-height: 30px;
    font-size: 12px;
    margin-bottom: 8px;
    width: 99%;
    overflow: hidden;

    .el-icon-collection-tag {
      margin-top: 16px;
      margin-right: 10px;
      color: #dbdbdb;
    }

    .voipBox {
      width: 24px;
      height: 24px;
      margin-right: 13px;
    }

    .enterRName {
      float: left;
      width: 115px;

      ::v-deep .el-input__inner {
        background: transparent !important;
        color: #ccc;
      }

      ::v-deep .el-input__inner::placeholder {
        color: #ccc;
      }
    }

    .shareToggleIcon {
      margin-right: -7px;
    }
  }

  .sourceSearch {
    width: 70%;
    height: 32px;
    margin-left: 8px;
    z-index: 1;
    /*position: relative;*/

    ::v-deep input {
      border: none;
    }

    ::v-deep .el-select {
      width: 70%;
    }

    ::v-deep .el-input__inner {
      color: #33ab4f;
    }

    ::v-deep .el-input__inner::-webkit-input-placeholder {
      color: #8d8d8d;
    }

    ::v-deep .el-cascader-panel.is-bordered {
      border: none;
      margin-top: 3px;
      position: relative;
      z-index: 2075;

      .el-cascader-menu {
        background-color: #242424;
      }

      .el-cascader-node__label {
        display: flex;
        color: #dbdbdb;
      }
      .el-cascader-node {
        font-weight: normal;
      }
    }

    ::v-deep .el-cascader-node {
      padding: 0 5px 0 5px;
    }
    .scrollbar {
      position: absolute;
      top: 34px;
      left: 0;
      width: 100%;
      height: 212px;
      overflow-y: auto;
      background: #444;
      margin-bottom: 30px;
    }

    .scrollbar li {
      display: block;
      width: 100%;
      color: #fff;
      height: 26px;
      line-height: 26px;
      cursor: pointer;
      position: relative;
      text-indent: 40px;
      text-align: left;
    }

    .scrollbar li:hover {
      background: #455f45;
    }

    ::v-deep .inputColorLive input {
      color: #33ab4f;
    }

    ::v-deep .inputColorOffline input {
      color: #676767 !important;
    }

    ::v-deep .inputColorOffline input::placeholder {
      color: #676767 !important;
    }

    ::v-deep .el-cascader {
      line-height: 32px;
      width: 100%;
    }

    ::v-deep .el-cascader-panel {
      height: 220px;

      .el-cascader-menu {
        height: 220px;
      }
    }

    ::v-deep .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }

  .switchSourcePeer {
    height: 30px;
  }
}
::v-deep .el-input {
  &.select-source {
    height: 32px;
    line-height: 32px;
    width: 68%;
    float: left;
    margin-left: 8px;
    .el-input__inner {
      height: 100%;
      color: #33ab4f;
      background: #444;
      border: none;
    }
  }
}

.sourceGreenBg {
  background: #39aa50 !important;
}

.sourceRedBg {
  background: #db1a14 !important;
}

.sourceGrayBg {
  background: #565656 !important;
}

.sourceTakeBtn {
  height: 30px;
  line-height: 8px !important;
  border: none !important;
  margin-left: 28px !important;
  background: #565656;
  color: #fff;
}

.liveBg {
  background: rgba(255, 51, 0, 1) !important;
  opacity: 0.76;
}

.offlineBg {
  background: rgba(153, 153, 153, 1) !important;
  opacity: 0.76;
}

.onlineBg {
  background: rgba(30, 195, 69, 1) !important;
  opacity: 0.76;
}

.red {
  color: #f00;
}

.gray {
  color: #999;
}

.ellipsis {
  width: 130px;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.ellipsisInput {
  width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /*text-align: left;*/
}

.volumeMSize {
  -moz-transform: scale(1.5, 1.5);
  -webkit-transform: scale(1.5, 1.5);
  -o-transform: scale(1.5, 1.5);
}

.volumeLSize {
  -moz-transform: scale(2, 2);
  -webkit-transform: scale(2, 2);
  -o-transform: scale(2, 2);
}
</style>
