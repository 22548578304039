import Tool from './utils.js'

class Drag {
  constructor () {
    this.target = null
    this.dragElement = null
  }

  create (data) {
    this.target = data.dropZones
    let eleList = []
    if (Tool.getType(data.from) === 'Array') {
      data.from.map(v => {
        eleList = [...eleList, ...v]
      })
    } else {
      eleList = data.from
    }
    eleList.forEach(v => {
      let that = this
      // 拖拽开始的时候触发
      v.ondragstart = function () {
        // 拖拽开始时方便获取被拖拽元素的数据
        that.dragElement = this
      }
    })
    this.target.ondragover = function (e) {
      // 阻止默认事件
      e.preventDefault()
    }
    this.target.ondrop = function (e) {
      data.dropEnd(e)
    }
  }
}
export default Drag
