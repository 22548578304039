/*
 *created by summer
 *2019-7-24
 *用来 埋点统计 advance control
 */

import { advancedControl } from '../eventAnalyze.config'

export function adAnalytice (type, data1, data2, option) {
  let str

  if (type == 'adStatus' || type == 'adType' || type == 'adRStatus') {
    // 过滤
    if (typeof data1.all == 'boolean') {
      if (data1.all) {
        str = `${type}allOpen`
      } else {
        str = `${type}allClose`
      }
    } else {
      Object.keys(data1).map(v => {
        Object.keys(data2).map(V => {
          if (v == V) {
            if (data1[v] != data2[V]) {
              // 改变项
              if (data1[v]) {
                str = `${type}${v}Open`
              } else {
                str = `${type}${v}Close`
              }
            }
          }
        })
      })
    }
  } else {
    str = data1 ? type + data1 : type
  }

  /*
   * console.info(43,str,advancedControl[str]);
   * 谷歌统计
   */
  try {
    if (option) {
      gtag('event', advancedControl[str] + `-${option}`)
    } else {
      gtag('event', advancedControl[str])
    }
  } catch (err) {}
}
