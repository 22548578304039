<template>
  <div
    class="info sourceItemBox"
    :draggable="true"
    :data-info="JSON.stringify(sourceItem)"
    :title="sourceItem.peerId">
    <div
      class="infoName f_left w_50 ellipsis"
      :class="showRedColor">{{ typeItem.name || typeItem.Name }}
    </div>
    <div
      class="sourceContent f_left w_40 ellipsis"
      :title="showContetnByType"
      :class="showRedColor">{{ showContetnByType }}</div>
    <div
      class="voipBox f_right w_10"
      v-if="showVoIP"
      @click="startVoIP()">
      <img
        :src="showButtonIcon"
        :title="voipIfbTitle" />
    </div>

    <div
      class="editBox f_right w_10"
      v-if="
        typeItem.type != 'T' &&
          typeItem.type != 'X' &&
          typeItem.type != 'Rtil'
      "
      @click="showEditBox()">
      <i
        class="iconfont"
        :class="showButtonIcon"></i>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { VoipImage } from '@/config.js'
import { Receiver, receviceParams } from '@/assets/api/common/receiver.js'

export default {
  props: {
    sourceItem: {
      type: Object,
      default: () => {
        return {
          status: 0,
          type: 'Pack'
        }
      }
    },
    typeItem: Object,
    rid: String
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      State: state => state,
      GetKernel: state => state.GetKernelStateObj
    }),
    showContetnByType () {
      const sourceType = this.typeItem.type
      if (sourceType) {
        return this.sourceItem.InputAddress
      } else {
        return this.typeItem.version
      }
    },
    showVoIP () {
      let flag = true
      if (
        (this.typeItem.type === 'T' || this.typeItem.type === 'X') &&
        this.sourceItem.Status &&
        (this.sourceItem.VoIPModel || this.sourceItem.IsIFBInUsed)
      ) {
        if (
          this.sourceItem.VoIPModel?.IsEnabled ||
          this.sourceItem.IsIFBInUsed
        ) {
          this.sourceItem.VoIPModel?.IsEnabled
            ? (this.voipIfbTitle = 'VoIP')
            : (this.voipIfbTitle = 'IFB')
          if (this.voipIfbTitle === 'VoIP') {
            flag = true
          } else {
            if (
              (this.sourceItem.IFBProperty?.IfbEnabled &&
                this.sourceItem.IFBProperty?.IfbEnableCheck &&
                !this.sourceItem.IFBProperty?.IsChecked) ||
              (this.sourceItem.IFBProperty?.IfbEnabled &&
                this.sourceItem.IFBProperty?.IfbEnableCheck &&
                this.sourceItem.IFBProperty?.IsChecked)
            ) {
              flag = true
            } else {
              flag = false
            }
          }
        } else {
          flag = false
        }
      } else {
        flag = false
      }
      return flag
    },
    showButtonIcon () {
      let _str = ''
      const type = this.typeItem.type || ''
      if (type === 'T' || type === 'X') {
        let key = 0
        if (this.voipIfbTitle === 'VoIP') {
          const isOpen = this.sourceItem.VoIPModel?.IsOpen
          const isConnection = this.sourceItem.VoIPModel?.IsConnecting
          if (isConnection && isOpen) {
            key = 1
          }
          if (!isConnection && isOpen) {
            key = 2
          }
        } else {
          if (this.sourceItem.IFBProperty) {
            if (
              this.sourceItem.IFBProperty?.IfbEnabled &&
              this.sourceItem.IFBProperty?.IfbEnableCheck &&
              this.sourceItem.IFBProperty?.IsChecked
            ) {
              key = 2
            }
          }
        }
        _str = VoipImage[key]
      }
      if (this.typeItem.type != 'T' && this.typeItem.type != 'X') {
        _str = 'editIcon'
      }
      return _str
    },
    showRedColor () {
      let _classStr = ''
      const type = this.typeItem.type
      const workMode = this.State.GetKernelStateObj.WorkMode
      if (this.sourceItem) {
        if (type === 'T') {
          const isFree = this.sourceItem.IsFree
          _classStr = isFree === false ? 'redColor' : ''
        } else {
          const currentLiveId = this.State.GetKernelStateObj.isFree
          if (currentLiveId === this.sourceItem.IdHex && workMode) {
            _classStr = 'redColor'
          }
          if (
            type == 'X' ||
            type === 'Ext' ||
            type === 'YouTube' ||
            type === 'SMPTE2022'
          ) {
            if (this.sourceItem.status == '2' || this.sourceItem.Status == '2') {
              _classStr = 'redColor'
            } else {
              _classStr = ''
            }
          }
          if (
            (this.typeItem.type == 'GLink' || this.typeItem.type == 'Rtil') &&
            workMode
          ) {
            if (
              this.GetKernel.CurrentTName == this.typeItem.name ||
              this.GetKernel.CurrentTName == this.typeItem.Name
            ) {
              _classStr = 'redColor'
            } else {
              _classStr = ''
            }
          }
        }
      }
      return _classStr
    }
  },
  methods: {
    startVoIP () {
      const id = this.sourceItem.IdHex
      const type = this.typeItem.type
      let dataStr = {
        rid: this.rid,
        data: '{"IdHex":"' + id + '"}'
      }
      if (type === 'X') {
        const params = Object.assign(
          dataStr,
          receviceParams.MediaDevice.getGridDeviceVoip
        )
        this.axios.post(Receiver().MediaDevice.getGridDeviceVoip, params).then(res => { }).catch(() => {})
      } else {
        if (this.sourceItem.VoIPModel?.IsEnabled) {
          const params = Object.assign(
            dataStr,
            receviceParams.MediaDevice.getMediaDeviceVoip
          )
          this.axios.post(Receiver().MediaDevice.getMediaDeviceVoip, params).then(res => { }).catch(() => {})
        } else {
          const isChecked = !this.sourceItem.IFBProperty?.IsChecked
          const ifbEnabled = this.sourceItem.IFBProperty?.IfbEnableCheck
          if (
            ifbEnabled &&
            this.sourceItem.IFBProperty?.IfbEnabled &&
            this.sourceItem.Status != 0
          ) {
            const dataStr = {
              rid: this.rid,
              data: '{"IdHex":"' + id + '","IsEnabled":' + isChecked + '}'
            }
            const params = Object.assign(
              dataStr,
              receviceParams.MediaDevice.getMediaDeviceIFB
            )
            this.axios.post(Receiver().MediaDevice.getMediaDeviceIFB, params).then(res => { }).catch(() => {})
          }
        }
      }
    },
    showEditBox () {
      this.sourceItem.type = this.typeItem.type
      this.sourceItem.name = this.typeItem.name
      this.sourceItem.url = this.typeItem.url
      this.sourceItem.sourceId = this.typeItem.peerId
      this.$emit('showEditDiv', this.sourceItem)
    }
  }
}
</script>
<style scoped lang="less">
::v-deep .el-icon-arrow-right:before {
  content: '\E613';
  font-size: 16px;
  color: #ccc;
}
.info {
  height: 35px;
  line-height: 35px;
  background: #333;
  text-align: left;
  padding-right: 10px;
  .infoName {
    text-indent: 12px;
  }
  .voipBox {
    width: 19px;
    height: 19px;
    margin-right: 7px;
    img {
      margin-top: -5px;
      cursor: pointer;
    }
  }
  .editBox {
    text-indent: 7px;
    height: 35px;
    padding-top: 2px;
  }
}
.info:hover {
  background-color: #455f45;
}
</style>
