var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'receiverItem',
    _vm.showSizeClass == 'm' ? 'sourceItemM' : '',
    _vm.showSizeClass == 'l' ? 'sourceItemL' : '',
  ]},[_c('div',{class:[
      'thumbnail-view-img pr',
      _vm.showSizeClass == 'm' ? 'imgMShow' : '',
      _vm.showSizeClass == 'l' ? 'imgLShow' : '',
    ],attrs:{"title":_vm.receiverItemOps.peerId}},[(_vm.socketItemData.status)?_c('div',{staticClass:"defaultBgImg",on:{"click":function($event){return _vm.showVideo(_vm.receiverItemOps.peerId)}}},[(_vm.socketItemData.status == 2)?_c('img',{staticClass:"receiverImg",attrs:{"src":_vm.freshImg,"alt":"","data-info":JSON.stringify({id:_vm.socketItemData.peerId, type: 'R'})}}):_vm._e(),(_vm.socketItemData.status == 1)?_c('img',{staticClass:"receiverImg",attrs:{"src":_vm.onlineImg,"alt":"","data-info":JSON.stringify({id:_vm.socketItemData.peerId, type: 'R'})}}):_vm._e(),(_vm.socketItemData.status == 0)?_c('img',{staticClass:"receiverImg offlineImg",attrs:{"src":_vm.offlineImg,"alt":"","data-info":JSON.stringify({id:_vm.socketItemData.peerId, type: 'R'})}}):_vm._e()]):_vm._e()]),_c('div',{staticClass:"receiverItemName"},[_c('span',[_vm._v(_vm._s(_vm.socketItemData.name))])]),_c('div',{staticClass:"switchSourcePeer"},[_c('el-input',{staticClass:"select-source",attrs:{"clearable":"","data-info":JSON.stringify({id:_vm.socketItemData.peerId, type: 'R'})},on:{"clear":_vm.clearDragInfo},model:{value:(_vm.selectName),callback:function ($$v) {_vm.selectName=$$v},expression:"selectName"}}),_c('videoButton',{key:_vm.receiverItemOps.peerId,style:({ float: _vm.showSizeClass == 'm' || 'l' ? 'right' : 'left', width: '73px'}),attrs:{"sourceItem":_vm.socketItemData,"hadSelectDevice":_vm.isSelectNewR,"flag":"R","deviceList":_vm.deviceList},on:{"changeHadSelectDevice":_vm.changeHadSelectDevice}})],1),(_vm.socketItemData && _vm.socketItemData.status == 2)?_c('div',{staticClass:"t_thumbnail_view_item_icon liveBg"},[_vm._v(" "+_vm._s(_vm.$t('lang.home_Live'))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }