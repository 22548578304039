<template>
  <div
    class="sourceItemBox"
    :draggable="true"
    :data-info="JSON.stringify(sourceItem)">
    <div
      class="box-top"
      :class="showRedBorder"
      @click="startVideo">
      <img
        :src="thumnailUrl"
        :onerror="errorImg"
        @mouseenter="showDeviceInfo($event)"
        @mouseleave="hideDeviceInfo($event)"
        :title="sourceItem.peerId" />
    </div>
    <div class="box-bottom">
      <div
        class="bottom-left f_left w_80"
        :title="typeItem.name"
        :class="{live: sourceItem.status == 2? true : false}">{{ typeItem.name || sourceItem.Name || typeItem.Name }}</div>
      <div
        class="bottom-right f_right"
        v-if="showVoIP"
        :class="{ notAllowed: !IFBClickAble }"><img
          :src="showButtonIcon"
          :title="voipIfbTitle" /></div>

      <div
        class="bottom-right f_right"
        v-if=" typeItem.type != 'T' && typeItem.type != 'X' && typeItem.type != 'Rtil' "
        @click="showEditBox()">
        <i
          class="iconfont"
          :class="showButtonIcon"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { VoipImage } from '@/config.js'
import { SHOWDEVICEIMG } from '@/assets/js/filters'

export default {
  props: {
    sourceItem: {
      type: Object,
      default: () => {
        return {
          status: 0,
          type: 'Pack'
        }
      }
    },
    imgItem: {
      type: Object,
      default: () => {
        return {
          status: 0,
          type: 'Pack',
          showThum: true
        }
      }
    },
    typeItem: Object,
    imgUrlTime: Object
  },
  data () {
    return {
      errorImg: 'this.src="' + require('@/assets/img/logo_thumbnail.png') + '"',
      youtube: require('@/assets/img/null.png'),
      voipIfbTitle: 'VoIP',
      firstFlag: true,
      lastUrl: '',
      lastTime: '',
      thumnailUrl: '',
      IFBClickAble: true,
      firstLoadImg: true // use it and loadtime to load img
    }
  },
  watch: {
    imgUrlTime: {
      handler (val) {
        if (this.firstLoadImg) {
          this.firstLoadImg = false
          this.firstLoadTime = new Date().getTime()
          this.thumnailUrl = SHOWDEVICEIMG(this.typeItem, true)
        } else {
          this.thumnailUrl = SHOWDEVICEIMG(this.sourceItem)
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      GetKernel: state => state.GetKernelStateObj
    }),
    showVoIP () {
      let flag = true
      if (
        (this.typeItem.type === 'T' || this.typeItem.type === 'X') &&
        this.sourceItem.Status &&
        (this.sourceItem.VoIPModel || this.sourceItem.IsIFBInUsed)
      ) {
        if (
          this.sourceItem.VoIPModel?.IsEnabled ||
          this.sourceItem.IsIFBInUsed
        ) {
          this.sourceItem.VoIPModel?.IsEnabled ? (this.voipIfbTitle = 'VoIP') : (this.voipIfbTitle = 'IFB')
          if (this.sourceItem.IFBProperty) {
            if ((!this.sourceItem.IFBProperty?.IfbEnableCheck &&
                !this.sourceItem.IFBProperty?.IsChecked) ||
              (!this.sourceItem.IFBProperty?.IfbEnableCheck &&
                this.sourceItem.IFBProperty?.IsChecked)
            ) {
              this.IFBClickAble = false
              this.voipIfbTitle = this.sourceItem.IFBProperty?.ToolTip
            } else {
              this.IFBClickAble = true
            }
          }
        } else {
          flag = false
        }
      } else {
        flag = false
      }
      return flag
    },
    showRedBorder () {
      let _str = ''
      const sourceId = this.sourceItem.IdHex
      if (this.GetKernel.CurrentTStrIdHex && sourceId) {
        if (
          this.GetKernel.CurrentTStrIdHex.toLowerCase() ===
          sourceId.toLowerCase() &&
          this.GetKernel.WorkMode === 1
        ) {
          _str = 'redBorder'
        }
      }
      if (
        (this.typeItem.type == 'GLink' || this.typeItem.type == 'Rtil') &&
        this.GetKernel.WorkMode === 1
      ) {
        if (
          this.GetKernel.CurrentTName == this.typeItem.name ||
          this.GetKernel.CurrentTName == this.typeItem.Name
        ) {
          _str = 'redBorder'
        }
      }

      return _str
    },
    showButtonIcon () {
      let _str = ''
      const type = this.typeItem.type || ''
      const item = this.sourceItem
      if (item.IdHex) {
        if (this.$store.state.selectSourceId == item.IdHex.toLowerCase()) {
          if (item.IsFree === false) {
            this.State.selectSourceStatus = 2
          } else {
            item.Status ? (this.State.selectSourceStatus = 1) : (this.State.selectSourceStatus = 0)
          }

          this.State.selectSourceType = 'T'
        }
      }
      if (type === 'T' || type === 'X') {
        let key = 0
        if (this.voipIfbTitle === 'VoIP') {
          const isOpen = item.VoIPModel?.IsOpen
          const isConnection = item.VoIPModel?.IsConnecting
          if (isConnection && isOpen) key = 1
          if (!isConnection && isOpen) key = 2
        } else {
          if (this.sourceItem.IFBProperty) {
            if (
              item.IFBProperty?.IfbEnabled &&
              item.IFBProperty?.IfbEnableCheck &&
              item.IFBProperty?.IsChecked
            ) {
              key = 2
            }
          }
        }
        _str = VoipImage[key]
      }
      if (this.typeItem.type != 'T' && this.typeItem.type != 'X') {
        _str = 'editIcon'
      }
      return _str
    }
  },
  methods: {
    showDeviceInfo (event) {
      this.sourceItem.type = this.typeItem.type
      if (this.typeItem.type == 'GLink' || this.typeItem.type == 'Rtil') {
        this.$emit('mouseenterCall', event, this.typeItem, 'show')
      } else {
        this.$emit('mouseenterCall', event, this.sourceItem, 'show')
      }
    },
    hideDeviceInfo (event) {
      this.sourceItem.type = this.typeItem.type
      if (this.typeItem.type == 'GLink' || this.typeItem.type == 'Rtil') {
        this.$emit('mouseleaveCall', event, this.typeItem, 'hide')
      } else {
        this.$emit('mouseleaveCall', event, this.sourceItem, 'hide')
      }
    },
    startVideo () {
      this.sourceItem.type = this.typeItem.type
      if (this.typeItem.type == 'GLink' || this.typeItem.type == 'Rtil') {
        this.$emit('clickCallBack', this.typeItem, {})
      } else {
        this.$emit('clickCallBack', this.sourceItem, {})
      }
    },
    showEditBox () {
      this.sourceItem.type = this.typeItem.type
      // console.log(this.typeItem,this.sourceItem)
      this.sourceItem.url = this.typeItem.url
      this.sourceItem.name = this.typeItem.name
      this.sourceItem.sourceId = this.typeItem.peerId
      // this.sourceItem.status
      if (this.showRedBorder == 'redBorder') {
        this.sourceItem.status = '2'
      }
      // console.log(this.showRedBorder)
      this.$emit('showEditDiv', this.sourceItem)
    }
  }
}
</script>
<style scoped lang="less">
.sourceItemBox {
  color: #dbdbdb;
  margin-bottom: 12px;
  width: 118px;
  margin-right: 10px;
  .box-top {
    height: 65px;
    background: #333;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .box-bottom {
    margin-top: 5px;
    height: 34px;
    .w_80 {
      width: 80%;
    }
    .line {
      cursor: pointer;
      text-decoration: underline;
    }
    .bottom-left {
      width: 77%;
      word-break: break-all;
      display: -webkit-box; /**对象作为伸缩盒子模型展示**/
      -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
      -webkit-line-clamp: 2; /**显示的行数**/
      overflow: hidden; /**隐藏超出的内容**/
      text-align: left;
      font-size: 12px;
      line-height: 1.4;
      &.live {
        color: #eb4040;
      }
    }
    .bottom-right {
      width: 24px;
      height: 24px;
      font-size: 12px;
      cursor: pointer;
      line-height: 24px;
      img {
        width: 16px;
        height: 16px;
        margin-bottom: 3px;
        vertical-align: text-bottom;
      }
      &.notAllowed {
        cursor: not-allowed;
      }
    }
  }
}
</style>
