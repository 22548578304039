<template>
  <li class="bookmark-item">
    <div
      class="img-box"
      @click="getChildrens">
      <el-image
        v-for="index in ['img1','img2','img3']"
        :key="index"
        :class="index"
        fit="cover"
        src="static/img/logo_thumbnail.b57dd1db.png">
      </el-image>
    </div>
    <div class="mark-info">
      <p class="mark-name">
        <i class="el-icon-folder"></i>&nbsp;&nbsp;{{data.bookmarkName}}</p>
      <el-input
        readonly
        class="select-source hide"
        v-model="selectGridName"></el-input>
      <el-button
        class="hide"
        type="primary"
        @click="applySource">Apply</el-button>
    </div>
  </li>
</template>
<script>
import Bus from '@/assets/js/vueBus'
export default {
  props: {
    data: Object,
    dragDomInfo: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      selectGridId: '',
      selectGridName: ''
    }
  },
  watch: {
    dragDomInfo (val) {
      if (!val) return
      this.selectGridId = val.peerId
      this.selectGridName = val.name
    }
  },
  methods: {
    applySource () {
      const obj = {
        peerId: this.selectGridId,
        name: this.selectGridName
      }
      if (this.selectGridId) Bus.$emit('setGridSource', obj)
    },
    getChildrens () {
      const obj = {
        id: this.data.id,
        name: this.data.bookmarkName,
        selectedGrid: this.selectGridId,
        selectedName: this.selectGridName
      }
      this.$emit('changeBookmark', obj)
      Bus.$emit('changeDevice', obj)
    }
  }
}
</script>
<style lang="less" scoped>
.bookmark-item {
  width: 270px;
  height: 188px;
  cursor: pointer;
  display: inline-block;
  margin: 15px 8px;
  .mark-info {
    width: 100%;
    height: 40px;
    background: #08130a;
    padding: 8px;
    margin-top: 9px;
    font-size: 14px;
    .mark-name {
      display: block;
      max-width: 60%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    ::v-deep .el-input {
      &.select-source {
        height: 32px;
        line-height: 32px;
        width: 70%;
        .el-input__inner {
          height: 100%;
          color: #33ab4f;
          background: #444;
          border: none;
        }
      }
    }
    .el-button {
      padding: 9px 16px;
      float: right;
      font-size: 12px;
    }
  }
  .img-box {
    position: relative;
    height: 156px;
    .img1 {
      position: absolute;
      z-index: 10;
      top: 12px;
      width: 100%;
      // box-shadow: 1px 2px 2px #444;
    }
    .img2 {
      position: absolute;
      z-index: 8;
      top: 6px;
      left: 6px;
      width: calc(100% - 12px);
      opacity: 0.6;
      box-shadow: 1px 2px 2px #444;
    }
    .img3 {
      position: absolute;
      z-index: 6;
      top: 0;
      left: 12px;
      width: calc(100% - 24px);
      opacity: 0.3;
      box-shadow: 0px 0px 15px 0px rgba(16, 4, 0, 0.72);
    }
  }
}
</style>
