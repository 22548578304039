<template>
  <div
    class="info"
    id="r_info_box"
    v-if="showInfo">
    <el-row>
      <el-col :span="4">
        <div class="titles">
          {{ $t("lang.receiverID") }}
        </div>
      </el-col>
      <el-col :span="4">
        <div class="green">
          {{ rId?rId:'N/A' }}&nbsp;
        </div>
      </el-col>
      <el-col :span="4">
        <div class="titles">
          {{ $t("lang.playerVersion") }}
        </div>
      </el-col>
      <el-col :span="4">
        <div class="green">{{ playerVersion ? playerVersion : 'N/A' }}&nbsp;</div>
      </el-col>
      <el-col :span="4">
        <div class="titles">{{ $t("lang.recRemain") }}</div>
      </el-col>
      <el-col :span="4">
        <div class="green">{{ recRemain ? recRemain : 'N/A' }}&nbsp;</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">
        <div class="titles">
          {{ $t("lang.productVersion") }}
        </div>
      </el-col>
      <el-col :span="4">
        <div class="green">
          {{ ProductBuildVersion || rVersion || 'N/A'}}&nbsp;
        </div>
      </el-col>
      <el-col :span="4">
        <div class="titles">{{ $t("lang.buildDate") }}</div>
      </el-col>
      <el-col :span="4">
        <div class="green">
          {{ BuildDate || 'N/A'}}&nbsp;
        </div>
      </el-col>
      <el-col :span="4">
        <div class="titles">{{ $t("lang.hardware") }}:</div>
      </el-col>
      <el-col :span="4">
        <div class="green">{{ HardWare || '' }}</div>
      </el-col>
    </el-row>
    <div v-if="rVersion && Number(rVersion) >= 7.7 && liveStatus">
      <hr class="line"/>
      <el-row>
        <el-col :span="4">
          <div class="titles">
            {{ $t("lang.IdleCpu") }}
          </div>
        </el-col>
        <el-col :span="4">
          <div class="green">
            {{ hardwareInfo && hardwareInfo.IdleCPU? hardwareInfo.IdleCPU + '%':'N/A' }}&nbsp;
          </div>
        </el-col>
        <el-col :span="4">
          <div class="titles">
            {{ $t("lang.CpuTemp") }}
          </div>
        </el-col>
        <el-col :span="4">
          <div class="green">{{ machineInfo.CpuTemp? machineInfo.CpuTemp + '&#8451;' : 'N/A' }}&nbsp;</div>
        </el-col>
        <el-col :span="4">
          <div class="titles">{{ $t("lang.FreeMem") }}</div>
        </el-col>
        <el-col :span="4">
          <div class="green">{{ hardwareInfo && hardwareInfo.StrFreeRAM && hardwareInfo.StrTotalRAM ? hardwareInfo['StrFreeRAM']+'/'+ hardwareInfo['StrTotalRAM']:'N/A' }}&nbsp;</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <div class="titles">
            {{ $t("lang.RemainSpace") }}
          </div>
        </el-col>
        <el-col :span="4">
          <div class="green">
            {{ hardwareInfo && hardwareInfo.StrRemainSpace && hardwareInfo.StrTotalSpace? hardwareInfo.StrRemainSpace + '/' + hardwareInfo.StrTotalSpace : 'N/A' }}&nbsp;
          </div>
        </el-col>
        <el-col :span="4">
          <div class="titles">{{ $t("lang.SpaceRemainTime") }}</div>
        </el-col>
        <el-col :span="4">
          <div class="green">
            {{ hardwareInfo && hardwareInfo.StrRemainTime? hardwareInfo.StrRemainTime : 'N/A' }}&nbsp;
          </div>
        </el-col>
      </el-row>
      <hr class="line"/>
      <el-row>
        <el-col :span="4">
          <div class="titles">
            {{ $t("lang.LoadAvg1Min") }}
          </div>
        </el-col>
        <el-col :span="4">
          <div class="green">
            {{ machineInfo.LoadAvg1Min? machineInfo.LoadAvg1Min : 'N/A' }}&nbsp;
          </div>
        </el-col>
        <el-col :span="4">
          <div class="titles">
            {{ $t("lang.LoadAvg5Min") }}
          </div>
        </el-col>
        <el-col :span="4">
          <div class="green">{{ machineInfo.LoadAvg5Min? machineInfo.LoadAvg5Min : 'N/A' }}&nbsp;</div>
        </el-col>
        <el-col :span="4">
          <div class="titles">{{ $t("lang.LoadAvg15Min") }}</div>
        </el-col>
        <el-col :span="4">
          <div class="green">{{ machineInfo.LoadAvg15Min? machineInfo.LoadAvg15Min : 'N/A' }}&nbsp;</div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { Receiver, receviceParams } from '@/assets/api/common/receiver.js'
import { RHardware } from '@/config.js'
import Tool from '@/assets/js/utils'
import { mapState } from 'vuex'
export default {
  props: {
    rId: String,
    showInfo: Boolean,
    liveStatus: {
      type: Boolean,
      default: false
    },
    fromPage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      machineInfo: {},
      recRemain: null,
      playerVersion: null,
      rVersion: '',
      ProductBuildVersion: '',
      BuildDate: '',
      HardWare: '',
      hardwareInfo: null,
      RHardware
    }
  },
  computed: mapState({
    pageRWebsocket: state => state.websocket.pageRWebsocket,
    stateRInfo: function () {
      return this.$store.state.common.deviceListReceiverDataData
    },
    hardWareList () {
      return this.$store.state.common.baseUrl.RHardware || this.RHardware
    }
  }),
  created () {
    this.getRversionInfo()
    this.showInfoFn()
  },
  watch: {
    stateRInfo (val) {
      if (val) {
        val.forEach(e => {
          const receiverInfo = Tool.isJSON(e.data) ? JSON.parse(e.data) : ''
          if (receiverInfo && (receiverInfo.rid == this.rId || (receiverInfo.StrId && receiverInfo.StrId.toLowerCase() == this.rId))) {
            this.ProductBuildVersion = receiverInfo.ProductBuildVersion ? receiverInfo.ProductBuildVersion : 'N/A'
            this.BuildDate = receiverInfo.BuildDate ? receiverInfo.BuildDate : 'N/A'
          }
        })
      }
    },
    rId () {
      this.getRversionInfo()
      this.showInfoFn()
    }
  },
  methods: {
    getRversionInfo () {
      if (!this.rId) return
      this.axios.get('/ccp/tvucc-device/device/getDevice?peerId=' + this.rId).then(res => {
        if (res.data.errorCode == '0x0' && res.data.result) {
          const result = res.data.result
          this.rVersion = result.version.substring(0, 3)
          if (this.rVersion && this.rVersion >= 7.6) this.sendRWebsoket()
          const hardWareNum = result.ext && result.ext.includes('PLT=') ? result.ext.split(';')[0].split('PLT=')[1] : ''
          this.HardWare = hardWareNum && this.hardWareList[hardWareNum] ? `${this.hardWareList[hardWareNum].Platform}  ${this.hardWareList[hardWareNum].Revision}` : 'N/A'
        } else {
          this.rVersion = 'N/A'
          this.HardWare = 'N/A'
        }
      }).catch(() => {
        this.rVersion = 'N/A'
        this.HardWare = 'N/A'
      })
    },
    showInfoFn () {
      if (!this.recRemain && this.rId) {
        const params = Object.assign({ rid: this.rId }, receviceParams.getRInfo.recTimeRemain)
        this.axios.post(Receiver().getRInfo.recTimeRemain, params).then(res => {
          if (res.data.errorCode == '0x0' && res.data.errorInfo == 'Success') {
            if (res.data.result.includes('StrRemainTime')) {
              this.recRemain = res.data.result && JSON.parse(res.data.result).StrRemainTime
            } else {
              this.recRemain = res.data.result
            }
          }
        }).catch(() => {})
      }
      if (!this.playerVersion && this.rId) {
        const params = Object.assign({ rid: this.rId }, receviceParams.getRInfo.playBackModel)
        this.axios.post(Receiver().getRInfo.playBackModel, params).then(res => {
          if (res.data.errorCode == '0x0' && res.data.errorInfo == 'Success') {
            if (res.data.result) {
              const data = JSON.parse(res.data.result)
              if (data && data.errorCode == '0x0') {
                this.playerVersion = data.PlayerVersion
              }
            }
          }
        }).catch(() => {})
      }
    },
    sendRWebsoket () {
      this.stopWebsoket()
      this.pageRWebsocket.send({
        // 视频信息
        message: { categoryId: '2152857600', operationType: '110', rid: this.rId },
        time: 2000,
        key: 'getReceiverMachineInfo',
        success: res => {
          let result = res.data ? JSON.parse(res.data) : {}
          if (result.LoadAvg1Min) {
            result.LoadAvg1Min = result.LoadAvg1Min.includes('%') ? result.LoadAvg1Min : result.LoadAvg1Min + '%'
            result.LoadAvg5Min = result.LoadAvg5Min.includes('%') ? result.LoadAvg5Min : result.LoadAvg5Min + '%'
            result.LoadAvg15Min = result.LoadAvg15Min.includes('%') ? result.LoadAvg15Min : result.LoadAvg15Min + '%'
            this.machineInfo = result
          }
        }
      })
      this.pageRWebsocket.send({
        message: { categoryId: '2152867584', operationType: '101', rid: this.rId },
        time: 2000,
        key: 'getRemainInfo',
        success: res => {
          if (res.data) {
            const result = JSON.parse(res.data)
            if (this.rVersion >= 7.2) {
              this.hardwareInfo = result
            }
          }
        }
      })
    },
    stopWebsoket () {
      if (this.$createWebsocket.pageRWebsocket) {
        this.$createWebsocket.pageRWebsocket('stop', 'getReceiverMachineInfo')
        this.$createWebsocket.pageRWebsocket('stop', 'getRemainInfo')
      }
    }
  },
  beforeDestroy () {
    this.stopWebsoket()
  }
}
</script>
<style lang="less" scoped>
.info {
  font-size: 14px;
  position: absolute;
  top: 52px;
  left: 0;
  z-index: 10000;
  background: #565656;
  line-height: 26px;
  padding: 5px 10px;
  width: 900px;
  .line{
    border: none;
    height: 1px;
    background-color: #686868;
    margin: 8px 0;
  }
  .green {
    color: #33ab4f;
    text-align: left;
  }

  .titles {
    text-align: left;
    color: #dfdfdf;
  }

  ::v-deep .el-col {
    padding: 0 5px !important;
  }

  ::v-deep .el-row {
    margin: 0 !important;
  }
}
</style>
