<template>
  <div
    class='voice clearfix'
    v-show='isShow.info'
    ref="voice"
    id='voice'>
    <div class="audio_scale f_left">
      <div class="grid">
        <div class="audio_scale_num pr">
          <span>+12</span>
        </div>
      </div>
      <div class="grid">
        <div class="audio_scale_num pr"></div>
      </div>
      <div class="grid">
        <div class="audio_scale_num pr"></div>
      </div>
      <div class="grid">
        <div class="audio_scale_num pr">
          <span class="scale_0">0</span>
        </div>
      </div>
      <div class="grid">
        <div class="audio_scale_num pr"></div>
      </div>
      <div class="grid">
        <div class="audio_scale_num pr"></div>
      </div>
      <div class="grid">
        <div class="audio_scale_num pr">
          <span class="scale_minus_12">-12</span>
        </div>
      </div>
      <div class="grid">
        <div class="audio_scale_num pr"></div>
      </div>
      <div class="grid">
        <div class="audio_scale_num pr">
          <span class="scale_minus_20">-20</span>
        </div>
      </div>
      <div class="grid">
        <div class="audio_scale_num pr"></div>
      </div>
      <div class="grid">
        <div class="audio_scale_num pr"></div>
      </div>
      <div class="grid">
        <div class="audio_scale_num pr"></div>
      </div>
      <div class="grid">
        <div class="audio_scale_num pr"></div>
      </div>
      <div class="grid">
        <div class="audio_scale_num pr">
          <span class="scale_minus_40">-40</span>
        </div>
      </div>
    </div>
    <div
      class="audio_box f_left"
      v-for='(item, index) in audioNumber'
      :key='index'>
      <div class="f_left audio_item">
        <div class="channel_bg pr">
          <!-- <div class="audio_value" :class='{voice_0: voice0}' :style='{height: 60 + "px"}'></div> -->
          <div
            class="audio_value"
            :style='{height: voiceInfo[index] + "px"}'></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/assets/js/vueBus.js'
import { setTimeout } from 'timers'
import { Receiver, receviceParams } from '@/assets/api/common/receiver.js'
export default {
  props: {
    pageRWebsocket: {
      type: Object,
      default: () => {
        return {}
      }
    },
    source: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rid: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      audioNumber: 8,
      voiceInfo: [],
      voiceWs: {},
      isShow: {
        info: true
      },
      currentRId: '',
      lastRId: '',
      liveInfoWidth: '4rem',
      sourceId: ''
    }
  },
  created () {
  },
  mounted () {
    Bus.$on('restoreDefaultConfig', () => {
      this.initData()
      this.isShow.info = false
      this.currentRId = ''
      setTimeout(() => {
        this.initData()
        this.isShow.info = true
      }, 500)
    })
  },
  computed: {
    getCurrentRInfo () {
      return this.$store.state.common.currentRInfo
    },
    listenVoiceData () {
      return this.$store.state.common.deviceListVolumeChartData
    }
  },
  watch: {
    getCurrentRInfo (newVal, oldVal) {
      if (this.source.type && this.source.type.toLowerCase() == 'r') return // my receiver页面不进行下面处理
      // 状态不是live时清空信息
      if (newVal.status == 0 && oldVal.status != newVal.status) {
        this.initData()
      }
      if (newVal.status != 0 && oldVal.status != newVal.status && newVal.livePeerId === this.sourceId) {
        this.getAudioNumber()
      }
      if (newVal.peerId != oldVal.peerId && newVal.livePeerId == this.source.peerId) {
        this.getAudioNumber()
      }
    },
    listenVoiceData (val) {
      const scale = this.$refs.voice.offsetHeight / 52
      val.map((v, i) => {
        if (v && v.data && v.rid == this.rid && this.source.status == 2) {
          this.voiceInfo = []
          const result = JSON.parse(v.data)
          if (result.DB) {
            result.DB.map((v, i) => {
              this.voiceInfo.push(Math.round((Number(v) + 40) * scale))
            })
          } else {
            this.voiceInfo.push(Math.round((Number(result.LeftDb) + 40) * scale))
            this.voiceInfo.push(Math.round((Number(result.RightDb) + 40) * scale))
          }
        }
      })
    },
    rid (newVal, oldVal) {
      if (newVal != oldVal) {
        this.initData()
      }
    },
    source: {
      // 切换T时
      handler (newVal, oldVal) {
        oldVal = oldVal || {}
        if (newVal.type && newVal.type.toLowerCase() != 'r') this.sourceId = newVal.peerId

        if (newVal && newVal.peerId && newVal.status == 2 && (newVal.peerId != oldVal.peerId) || oldVal == undefined) {
          this.initData()
          if (newVal.type && newVal.type.toLowerCase() == 'r') {
            this.getAudioNumber(newVal.peerId)
          } else {
            this.getAudioNumber(newVal.livePeerId)
          }
        }
        // 切换到状态为2时
        if (newVal.status == 2 && (oldVal == undefined || newVal.status != oldVal.status)) {
          if (newVal.type && newVal.type.toLowerCase() == 'r') {
            this.getAudioNumber(newVal.peerId)
          } else {
            this.getAudioNumber(newVal.livePeerId)
          }
        }
        // 切换到状态不为2时
        if (newVal.status && newVal.status != 2) {
          this.initData()
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 初始化数据
    initData (callback) {
      this.audioNumber = 8
      this.voiceInfo = [0, 0, 0, 0, 0, 0, 0, 0]
      callback && callback()
    },
    // 获取音柱的条数
    getAudioNumber (id) {
      const peerId = id || this.$store.state.common.currentRInfo.peerId
      if (peerId == undefined || peerId == '') return
      const param = Object.assign({ rid: peerId }, receviceParams.Channel.channelNumber)
      this.axios.post(Receiver().Channel.channelNumber, param).then(res => {
        if (res.status == 200 && res.data.errorCode == '0x0') {
          if (!JSON.parse(res.data.result).errorCode) {
            const result = JSON.parse(res.data.result)
            let ChannelCount = result.ChannelCount
            if (ChannelCount == 2) ChannelCount = 8
            setTimeout(() => {
              this.audioNumber = ChannelCount
              if (this.audioNumber == 8) this.liveInfoWidth = '4rem'
              if (this.audioNumber == 16) this.liveInfoWidth = '3rem'
              if (this.audioNumber == 2) this.liveInfoWidth = '5rem'
              Bus.$emit('liveInfoWidth', this.liveInfoWidth)
            }, 500)
          }
        } else {
          this.audioNumber = 8
          this.liveInfoWidth = '4rem'
          Bus.$emit('liveInfoWidth', this.liveInfoWidth)
        }
      }).catch(() => {})
    }
  }
}
</script>
<style lang='less'>
.voice {
  height: 100%;
  .audio_scale {
    height: 100%;
    width: 2px;
    background: #999;
    position: relative;
    .grid {
      height: 7.63%;
    }
    .audio_scale_num {
      width: 6px;
      height: 2px;
      background-color: #999;
      margin-left: -5px;
      font-size: 12px;
      color: #999;
      span {
        position: absolute;
        top: -5px;
        left: -29px;
      }
      .scale_0 {
        left: -19px;
      }
      .scale_minus_12 {
        left: -28px;
      }
      .scale_minus_20 {
        left: -30px;
      }
      .scale_minus_40 {
        left: -30px;
      }
    }
  }
  .audio_box {
    height: 100%;
    margin-left: 8px;
    .audio_item {
      width: 6px;
      height: 100%;
    }
    .channel_bg {
      width: 100%;
      height: 100%;
      background: #333;
    }
    .audio_value {
      width: 100%;
      background: #33ab4f;
      position: absolute;
      bottom: 0;
      transition: height 0.1s;
    }
  }
  .voice_0 {
    height: 10px !important;
  }
}
@media (max-width: 1279px) {
  .voice .audio_box {
    margin-left: 5px;
  }
  .voice .audio_box .audio_item {
    width: 3px;
  }
}
</style>
