<template>
  <div class="receiverContent myReceiverContent">

    <div
      v-for="(val,key) in receiverList"
      :key="val.peerId"
      :class="[val.isDis ? 'videoDown':'receiverItemBorder',showSizeClass == 'm'&&!val.isDis?'sourceItemM':showSizeClass == 'l'&&!val.isDis?'sourceItemL':'']"
      :style="{'margin-top':val.isDis&&showSizeClass == 'm'||val.isDis&&showSizeClass == 'l'?'10px':'5px'}">
      <device-item
        :imgUrlTime='imgUrlTime'
        :receiverItemOps="val"
        :key="val.peerId"
        :listKey="key"
        @showVideo="showVideo"
        :socketItemDatas="allReceiverData[val.peerId]&&allReceiverData[val.peerId].peerId ? allReceiverData[val.peerId] : val"
        ref="videoDown"
        v-if="!val.isDis"
        :class="{clickClass:val.peerId == currentRId}"
        @getSelectInfoContent="getSelectInfoContent"
        :locationInfo="locationInfo"
        :bookmarkObj='bookmarkObj'
        :locationShow="locationShow"
        :setDomInfo="setDomInfo"
        :dragDomInfo="setDomInfo && setDomInfo.id == val.peerId && setDomInfo.type == 'R' || setDomInfo && setDomInfo.type == 'set'? dragDomInfo : null">
      </device-item>
      <div
        class="triangle_border_up"
        v-show="val.peerId == currentRId"
        :style="{'bottom':showSizeClass == 'm'||showSizeClass == 'l'?'-20px':'-7px'}"></div>
      <div
        v-if="val.isDis"
        class="boxVIdeo">
        <device-info
          :key="key"
          class=""
          v-show="showOrHide"
          deviceType="R"
          pn="mr"></device-info>
      </div>
    </div>
  </div>
</template>
<script>

import deviceItem from './deviceItem'
import deviceInfo from './deviceInfo.vue'
import { mapState } from 'vuex'
import Bus from '@/assets/js/vueBus'
import { myReceiversAnalyze } from '@/assets/js/googleAnalyze/myReceiver.js'

export default {
  components: {
    deviceItem,
    deviceInfo
  },
  props: {
    socketObj: Array, // websocket中的R信息
    queryRlist: Array, // 获取的R列表
    bookmarkObj: Object, // 获取的R初始化列表
    imgUrlTime: Object,
    setDomInfo: {
      type: Object,
      default: null
    },
    dragDomInfo: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      peerIds: [],
      allReceiverData: this.socketObj,
      currentRId: '', // 当前的R的Id
      currentReceiverData: {},
      oldSourceId: '', // 记录上一个点击的sourceId
      currentIndex: -1, // 当前点击的index
      showOrHide: false,
      currentSID: '', // 当前的选择的source的id
      isDisplay: [], // 使用此数组来判断哪一个播放组件显示
      receiverList: this.queryRlist,
      receiverListSave: [], // 保存一份receiverList
      numOfLine: 0, // 记录一行最多显示几个source
      isUpdate: true, // 是否可以从监听中更新数据
      currentShowIndex: null, // 记录当前下拉的下标
      locationInfo: [],
      lang: 'en',
      locationShow: true
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      pageRequest: state => state.websocket.pageRequest,
      showSizeClass: state => state.common.showRecevierSize
    })
  },
  watch: {
    queryRlist (val) { // R列表
      this.receiverList = val
      this.receiverListSave = [] // 将最新的值更新
      this.currentRId = ''
      this.oldSourceId = ''
      if (this.receiverList) {
        val.map(v => {
          this.receiverListSave.push(v)
        })
      }
    },
    socketObj (val) { // websocket中的值
      this.allReceiverData = val
      if (this.currentRId) { // 当前有选中的R
        this.currentReceiverData = val[this.currentRId]
        if (this.currentReceiverData && this.currentReceiverData.peerId) {
          if (this.showOrHide) { // 如果有下拉 1.如果当前在live 将此live的R的id传给播放组件 2.如果没在live 将sourceItem传过来的rid传给播放组件
            var dataObj = {
              rid: this.currentRId,
              sourceItem: this.currentReceiverData,
              isDown: true
            }
            Bus.$emit('showReceiverInfo', dataObj)
          } else {
            const dataObj = {
              rid: '',
              sourceItem: {},
              isDown: false
            }
            Bus.$emit('showReceiverInfo', dataObj)
          }
        }
      }
    },
    showSizeClass: {
      handler (oldVal, newVal) {
        if (oldVal != newVal) {
          this.handleResize()
        }
      }
    }
  },
  mounted () {
  },
  created () {
    this.lang = window.localStorage.getItem('lang')
    this.sendWebSocketOnce()
    window.sessionStorage.setItem('canClick', 'true')
    this.receiverListSave = [] // 将最新的值更新
    if (this.receiverList) {
      this.receiverList.map(v => {
        this.receiverListSave.push(v)
      })
    }
    if (this.showSizeClass == 's') {
      this.numOfLine = parseInt($('.receiverContent').width() / 364) // 一行显示几个
    } else if (this.showSizeClass == 'm') {
      this.numOfLine = parseInt($('.receiverContent').width() / 602) // 一行显示几个
    } else if (this.showSizeClass == 'l') {
      this.numOfLine = parseInt($('.receiverContent').width() / 910) // 一行显示几个
    }
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    // 调整窗口
    handleResize () {
      if (this.showSizeClass == 's') {
        this.numOfLine = parseInt($('.receiverContent').width() / 286) // 一行显示几个
      } else if (this.showSizeClass == 'm') {
        this.numOfLine = parseInt($('.receiverContent').width() / 602) // 一行显示几个
      } else if (this.showSizeClass == 'l') {
        this.numOfLine = parseInt($('.receiverContent').width() / 910) // 一行显示几个
      }
      let numOfAll = 0
      if (this.queryRlist) {
        numOfAll = this.queryRlist.length // 总source数量
      }
      var witchLine = Math.ceil(this.currentShowIndex / this.numOfLine) // 第几行
      let indexInsert = -1
      var thisAll = witchLine * this.numOfLine // 到这一行最多可以显示多少个source
      if (thisAll > numOfAll) { // 如果到这一行最多显示的个数小于总数量
        indexInsert = numOfAll - 1 // 将要插入的下标记录
      } else {
        indexInsert = witchLine * this.numOfLine - 1// 这个是这一行最后一个source的下标
      }
      this.copyData(indexInsert, true)
    },
    getSelectInfoContent (data) {
      if (this.showOrHide && this.currentReceiverData.peerId == data.deviceList.split(',')[1]) { // 如果下拉式打开的 并且当前打开的source和发送过来的source是同一个 更新rid
        this.currentSID = data.deviceList.split(',')[0]
      }
    },
    copyData (index, isResize) { // copy 数组(将物理引用地址更改->以便保存初始数据)
      this.receiverList = []
      this.receiverListSave.map(v => {
        this.receiverList.push(v)
      })
      if (index || index == 0) {
        if (index > -1) {
          this.receiverList.splice(index + 1, 0, { isDis: true })
        }
      }
      // 谷歌统计
      let event = 'Up'
      this.receiverList.map((v, i) => {
        if (v.isDis) event = 'Down'
      })
      if (!isResize) {
        myReceiversAnalyze(`myReceiversPreviewVideo${event}`, this.$route.path) // 谷歌统计 receiver关闭/打开视频下拉
      }
    },
    showVideo (data, str) {
      this.showOrHide = false// 先将showOrhide置为false 防止播视频组件接收不到

      this.currentReceiverData = data.currentReceiverData // 将sourceData更新
      this.currentSID = data.sourceId

      if (this.showSizeClass == 's') {
        this.numOfLine = parseInt($('.receiverContent').width() / 286) // 一行显示几个
      } else if (this.showSizeClass == 'm') {
        this.numOfLine = parseInt($('.receiverContent').width() / 602) // 一行显示几个
      } else if (this.showSizeClass == 'l') {
        this.numOfLine = parseInt($('.receiverContent').width() / 910) // 一行显示几个
      }
      this.currentRId = data.rid
      let prevKey = 0 // 上一次下拉按钮插入到的index
      this.receiverList.map((v, i) => {
        if (v.isDis == true) {
          prevKey = i
        }
      })
      let index = data.key + 1 // 当前第几个source
      if (index > prevKey && prevKey != 0) { // 如果当前点击的key大于以前插入的key key -1
        index = index - 1
      }
      this.currentIndex = data.key
      let numOfAll = 0
      const _this = this
      if (this.queryRlist) {
        numOfAll = this.queryRlist.length // 总source数量
      }
      this.currentShowIndex = index
      var witchLine = Math.ceil(index / this.numOfLine) // 第几行
      let indexInsert = -1
      var thisAll = witchLine * this.numOfLine // 到这一行最多可以显示多少个source
      if (thisAll > numOfAll) { // 如果到这一行最多显示的个数小于总数量
        indexInsert = numOfAll - 1 // 将要插入的下标记录
      } else {
        indexInsert = witchLine * this.numOfLine - 1// 这个是这一行最后一个source的下标
      }
      if (this.currentRId == this.oldSourceId || str == 'resize') { // 点击的是同一个source  关闭下拉
        this.showOrHide = false
        this.copyData()
        this.oldSourceId = ''
        this.isUpdate = true
        this.currentRId = ''
        this.currentIndex = -1
        this.currentShowIndex = null
      } else { // 开启下拉
        this.isUpdate = false
        this.copyData(indexInsert)
        this.oldSourceId = this.currentRId
        const peerId = this.currentRId
        Bus.$emit('needToUpdateMatadata', peerId) // 给matadata获取信息用的
        let top = 0
        if (indexInsert - 1 >= 0) {
          top = $('.el-main').scrollTop() + $($('.receiverItem ')[indexInsert - 1]).offset().top - $('.el-main').offset().top + 150
        }
        this.showOrHide = false// 先将showOrhide置为false 防止播视频组件接收不到
        $('.el-main').animate({
          scrollTop: top
        }, 'slow', function () {
          _this.showOrHide = true
        }
        )
      }
    },
    sendWebsocket () {
      let sendParam = []
      if (this.receiverList) {
        this.receiverList.map(item => {
          if (this.allReceiverData[item.peerId] && this.allReceiverData[item.peerId].status == 2) {
            if (item.livePeerId) {
              sendParam.push(item.livePeerId.slice(0, 16))
            }
          }
        })
      }
      sendParam = [...new Set(sendParam)]
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'getLocationByIps') // 停止websocket taskInfo_R_W这个可以自定义 发送什么返回什么
      }
      this.lang = window.localStorage.getItem('lang')
      let mapType = ''
      if (this.lang == 'zh') {
        mapType = 'baidu'
      }
      this.pageRequest.send({
        message: {
          type: 'getLocationByIps',
          module: 'getLocationByIps',
          data: {
            peerIds: sendParam.join(','),
            peerIps: '',
            mapType: mapType
          }
        },
        time: 60000,
        key: 'getLocationByIps',
        success: res => {
          const data = res.result
          let result = ''
          if (data.errorCode == '0x0') {
            if (data.result) {
              if (JSON.parse(data.result) && JSON.parse(data.result).data && JSON.parse(data.result).data.length > 0) {
                result = JSON.parse(data.result).data
                this.locationInfo = []
                this.locationInfo = result
              }
            }
          }

          let newParam = []
          if (this.receiverList) {
            this.receiverList.map(item => {
              if (this.allReceiverData[item.peerId] && this.allReceiverData[item.peerId].status == 2) {
                if (item.livePeerId) {
                  newParam.push(item.livePeerId.slice(0, 16))
                }
              }
            })
          }
          newParam = [...new Set(newParam)]
          const taskInfoMsg = {
            type: 'getLocationByIps',
            module: 'getLocationByIps',
            data: {
              peerIds: newParam.join(','),
              peerIps: '',
              mapType: mapType
            }
          }
          this.pageRequest.sendMsgs.getLocationByIps.message = taskInfoMsg
          // }
        }
      })
    },
    sendWebSocketOnce () { // 开始发送一次 以后没60s发送一次
      this.lang = window.localStorage.getItem('lang')
      let mapType = ''
      if (this.lang == 'zh') {
        mapType = 'baidu'
      }
      let sendParam = []
      if (this.receiverList) {
        this.receiverList.map(item => {
          if (item.status == 2) {
            if (item.livePeerId) {
              sendParam.push(item.livePeerId.slice(0, 16))
            }
          }
        })
      }
      sendParam = [...new Set(sendParam)]
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'getLocationByIps')
      }
      if (sendParam.length <= 0) {
        return false
      }
      this.pageRequest.send({
        message: {
          type: 'getLocationByIps',
          module: 'getLocationByIps',
          data: {
            peerIds: sendParam.join(','),
            peerIps: '',
            mapType: mapType
          }
        },
        time: 0,
        key: 'getLocationByIps',
        isOnce: true,
        success: res => {
          const data = res.result
          let result = ''
          if (data.errorCode == '0x0') {
            if (data.result) {
              if (JSON.parse(data.result) && JSON.parse(data.result).data && JSON.parse(data.result).data.length > 0) {
                result = JSON.parse(data.result).data
                this.locationInfo = []
                this.locationInfo = result
                if (this.$createWebsocket.pageRequest) {
                  this.$createWebsocket.pageRequest('stop', 'getLocationByIps')
                }
                this.sendWebsocket()
              }
            }
          }
        }
      })
    }
  },
  beforeDestroy () {
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', 'getLocationByIps') // 停止websocket taskInfo_R_W这个可以自定义 发送什么返回什么
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  sendCloseInfo () { // 关闭下拉播放 或者切换source播放的时候通知播放组件重置数据
    const dataObj = {
      sourceItem: {},
      isDown: false
    }
    Bus.$emit('showReceiverInfo', dataObj)
  }
}
</script>

<style lang="less" scoped>
.clickClass {
  border: 2px solid rgba(51, 171, 79, 1);
}

.boxVIdeo {
  height: 480px;
  width: 100%;
}

.draw-enter-active,
.draw-leave-active {
  transition: all 0.6s ease;
}

.draw-enter,
.draw-leave-to {
  height: 0;
}

.receiverContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.receiverItemBorder {
  position: relative;
  width: 270px;
  margin: 0 5px;
}

.receiverItem:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.52);
}

.videoDown {
  display: block;
  // height: 480px;
  width: 99.3% !important;
  background: rgba(1, 1, 1, 0.5);
  /*transition: all ease 0.5s;*/
  float: left;
  margin: 18px 0;
  /* overflow: hidden; */
  margin-top: 5px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.56);
  border-radius: 8px;
  left: 0;
  padding: 0 20px;
  position: relative;
}

.triangle_border_up {
  width: 0;
  height: 0;
  border-width: 0 12px 14px;
  border-style: solid;
  border-color: transparent transparent rgba(1, 1, 1, 0.5); /*透明 透明  灰*/
  position: absolute;
  bottom: -7px;
  left: 152px;
}

.videoDown .leftDistance {
  left: 30px;
}

.marginRight0 {
  margin-right: 0 !important;
}

.videoDownContent {
  clear: both;
  width: 100%;
  height: 0;
  background: rgba(1, 1, 1, 0.5);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.56);
  margin: 9px 0 18px 0;
  padding: 0 20px;
}

@media (min-width: 1921px) {
  .videoDown {
    height: 580px;
  }
  .receiverItemBorder {
    position: relative;
    height: 301px;
  }
}
@media (max-width: 1919px) {
  .receiverContent {
    width: 1508px;
  }

  .videoDown {
    height: 960px;
  }
}

// @media (max-width: 1536px) {
@media (max-width: 1599px) {
  .receiverContent {
    width: 1136px;
  }
}

@media (max-width: 1279px) {
  .receiverContent {
    width: 754px;
  }

  .videoDown {
    height: 817px;
  }
}

@media (max-width: 759px) {
  .videoDown {
    padding: 0;
    height: 870px;
  }

  .receiverContent {
    width: 369px;
  }
}
</style>
