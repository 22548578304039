<template>
  <div
    class="content"
    id="drag-wrap">
    <div class="device-search">
      <div class="title f_left">{{ $t('lang.receivers') }}</div>
      <div class="statusBox">
        <span class="statusName">{{ $t('lang.status') }}:</span>
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          class="allStatus">
          {{ $t('lang.all') }}
        </el-checkbox>
        <el-checkbox-group
          v-model="checkedCities"
          @change="handleCheckedCitiesChange"
          class="statusCheckBox">
          <el-checkbox
            v-for="filter in filterOptionObj"
            :label="filter"
            class="statusCheck"
            :class="{
              red: filter == 'live',
              green: filter == 'online',
              lightGrey: filter == 'offline',
            }"
            :key="filter">{{ $t(`lang.${filter}`) }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <Pagination
        v-if="queryRlist.length > 0"
        @current-change="handleCurrentChange"
        :current-page="params.pageNum"
        :pageSize="params.pageSize"
        :total="total"
        @size-change="handleSizeChange"
        layout="prev, pageF, next, pageL, nowSize, jumper">
      </Pagination>
      <div
        class="pages"
        v-if="showOperate && params.bookmarkId != 'Other' && queryRlist.length > 0">
        <el-input
          v-model="inputName"
          :data-info="JSON.stringify({id: params.bookmarkId, type: 'all'})"
          class="drag_content"
          :placeholder="$t('lang.dragHere')"
          clearable
          @clear="clearInput"></el-input>
        <el-button
          :loading="preparing"
          class="black operate-btn"
          @click="applyGrid"
          :disabled="inputName == '' || prepared">{{ preparing? `${$t('lang.applying')} ${countdown}` : $t('lang.apply') }}</el-button>
        <el-button
          v-if="oneSource || prepared"
          class="black operate-btn"
          @click.stop="startAll">{{ $t('lang.startAll') }}</el-button>
        <el-button
          :loading="stopLoading"
          class="black operate-btn"
          @click.stop="stopAll">{{ $t('lang.stopAll') }}</el-button>
      </div>
    </div>
    <div
      class="listBox"
      v-loading='loading'
      element-loading-background="rgba(0, 0, 0, 0.8)">
      <!--引入receiver的组件-->
      <device-content
        :queryRlist="queryRlist"
        v-if="showReceiver"
        :imgUrlTime='imgUrlTime'
        :socketObj="socketObj"
        :setDomInfo="setDomInfo"
        :dragDomInfo="dragDomInfo"></device-content>
    </div>
    <message-dialog :info="info"></message-dialog>
  </div>
</template>

<script>
import DeviceContent from './deviceContent.vue'
import Pagination from '@/components/Pagination'
import Bus from '@/assets/js/vueBus.js'
import { mapState, mapMutations } from 'vuex'
import DragMethod from '@/assets/js/drag'
import MessageDialog from './messageDialog'
import toTips from '@/assets/js/toTipsObj'
const cityOptions = ['live', 'online', 'offline']
export default {
  name: 'Index',
  components: {
    DeviceContent,
    Pagination,
    MessageDialog
  },
  data () {
    return {
      loading: true,
      queryRlist: [],
      peerIds: '', // 所有的Rid
      liveRids: [],
      socketObj: [],
      initCurrentInfoSocket: true,
      showReceiver: false,
      imgTimer: null,
      imgUrlTime: {
        current: {},
        last: {}
      },
      params: {
        bookmarkId: 'Other',
        pageNum: 1,
        pageSize: 15,
        search: '',
        offline: 'true',
        online: 'true',
        live: 'true'
      },
      total: 10,
      checkAll: true,
      isIndeterminate: false,
      filterOptionObj: ['live', 'online', 'offline'],
      checkedCities: ['live', 'online', 'offline'],
      cities: cityOptions,
      BindDragMethod: {},
      inputName: '',
      dragDomInfo: {},
      setDomInfo: {},
      setInput: false,
      oneSource: false,
      showOperate: true,
      info: {
        show: false,
        errorArr: [],
        successArr: []
      },
      preparing: false,
      prepared: false,
      countdown: 5,
      stopLoading: false,
      intervalTimer: null
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      pageRequest: state => state.websocket.pageRequest,
      pageRWebsocket: state => state.websocket.pageRWebsocket
    })
  },
  created () {
    const obj = {
      id: 'Other',
      name: 'Receivers'
    }
    this.getReceiverList(obj)
    Bus.$on('changeDevice', itm => {
      this.dragDomInfo = {}
      this.setDomInfo = {}
      this.params.pageNum = 1
      this.getReceiverList(itm)
    })
    Bus.$on('bindDragEvent', () => {
      this.$nextTick(() => {
        this.bindingDrag()
      })
    })
    Bus.$on('searchReceiver', itm => {
      this.params.pageNum = 1
      this.params.search = itm.search
      this.showOperate = itm.search == ''
      this.getReceiverList(itm)
    })
    Bus.$on('hideStartFun', () => {
      this.oneSource = false
    })
  },
  watch: {
    liveRids (val) { // 监听receiverlist的变化 用以停止currentRInfo 的websocket 防止数据获取不到
      if (val.length > 0) { // 开启websocket
        if (this.initCurrentInfoSocket) {
          this.createMsgWebsocket()
          this.updateParams()
          this.initCurrentInfoSocket = false
        }
      } else { // 关闭websocket
        this.initCurrentInfoSocket = true
        this.stopupdateParams()
      }
    }
  },
  methods: {
    ...mapMutations('common', [
      'DEVICELIST_VOLUMECHARTDATA',
      'DEVICELIST_SLOTINFODATA',
      'DEVICELIST_RECEIVERDATADATA',
      'DEVICELIST_CURRENTRINFODATA',
      'DEVICELIST_SETINFODATA'
    ]),
    clearInput () {
      this.setInput = false
      this.setDomInfo = null
      this.dragDomInfo = null
      this.inputName = ''
      this.prepared = false
      this.oneSource = false
    },
    applyGrid () {
      this.preparing = true
      this.prepared = false
      this.axios.get(`/ccp/tvucc-user/userDevice/gridSwitchPreload?rids=${this.peerIds.join(',')}&sourceId=${this.dragDomInfo.peerId}`, {
        contentType: 'application/json;charset=UTF-8'
      }).then(res => {
        if (res.data.errorCode == '0x0') {
          this.intervalTimer = setInterval(() => {
            this.countdown = this.countdown - 1
            if (this.countdown == 0) {
              this.prepared = true
              this.setInput = false
              this.oneSource = true
              this.inputName = ''
              this.setDomInfo.type = 'set'
              this.preparing = false
              this.countdown = 5
              clearInterval(this.intervalTimer)
            }
          }, 1000)
        } else {
          this.countdown = 5
          this.preparing = false
        }
      }).catch(() => {
        this.countdown = 5
        this.preparing = false
      })
    },
    startAll () {
      this.loading = true
      this.axios.post('/ccp/tvucc-user/userDevice/selectLivingR', { bookmarkId: this.params.bookmarkId }, {
        contentType: 'application/json;charset=UTF-8'
      }).then(res => {
        this.countdown = 5
        if (res.data.errorCode == '0x0') {
          if (res.data.result && res.data.result.length > 0) {
            this.$confirm(this.$t('lang.ReceiverLiveWithOther'), '', {
              confirmButtonText: this.$t('lang.Ok'),
              cancelButtonText: this.$t('lang.cancel')
            }).then(() => {
              this.startLive()
            }).catch(() => {
              this.loading = false
            })
          } else {
            this.startLive()
          }
        } else {
          this.loading = false
          this.$message({
            type: 'info',
            message: res.data.errorInfo
          })
        }
      }).catch(() => {
        this.countdown = 5
        this.loading = false
      })
    },
    startLive () {
      const params = {
        bookmarkId: this.params.bookmarkId,
        sourceId: this.dragDomInfo.peerId
      }
      this.axios.post('/ccp/tvucc-user/userDevice/startAllByBookMarkId', params, {
        contentType: 'application/json;charset=UTF-8'
      }).then(res => {
        this.loading = false
        this.oneSource = false
        const result = res.data.result
        if (res.data.errorCode == '0x0') {
          if ((!result || !result.errorList) || result && !result.errorList) {
            this.$message({
              type: 'success',
              message: this.$t('lang.success')
            })
          } else {
            this.info.show = true
            if (result.errorList.length > 0) {
              result.errorList.forEach(itm => {
                if (itm.errorCode == '81100107') {
                  itm.errorInfo = this.$t('lang.notPaired')
                } else {
                  itm.errorInfo = this.$t(toTips[itm.errorCode]) || itm.errorInfo
                }
              })
            }
            this.info.errorList = result.errorList || []
            this.info.successList = result.successList || []
          }
        } else {
          this.$message({
            type: 'info',
            message: res.data.errorInfo
          })
        }
      }).catch(() => {
        this.loading = false
      })
    },
    stopAll () {
      this.stopLoading = true
      this.axios.post(`/ccp/tvucc-user/userDevice/stopAllByBookMarkId?bookmarkId=${this.params.bookmarkId}`, {
        contentType: 'application/json;charset=UTF-8'
      }).then(res => {
        this.stopLoading = false
        const result = res.data.result
        if (res.data.errorCode == '0x0') {
          this.clearInput()
          if ((!result || !result.errorList) || result && !result.errorList) {
            this.$message({
              type: 'success',
              message: this.$t('lang.success')
            })
          } else {
            this.info.show = true
            if (result.errorList.length > 0) {
              result.errorList.forEach(itm => {
                itm.errorInfo = this.$t(toTips[itm.errorCode]) || itm.errorInfo
              })
            }
            this.info.errorList = result.errorList || []
            this.info.successList = result.successList || []
          }
        } else {
          this.$message({
            type: 'info',
            message: res.data.errorInfo
          })
        }
      }).catch(() => {
        this.stopLoading = false
      })
    },
    getReceiverList (obj) {
      this.loading = true
      this.showReceiver = false
      if (obj) this.params.bookmarkId = obj.id
      if (this.params.bookmarkId == 0) this.params.bookmarkId = 'Other'
      this.axios.post('/ccp/tvucc-user/userDevice/pageInfoRByBookMark', this.params, {
        contentType: 'application/json;charset=UTF-8'
      }).then(res => {
        this.loading = false
        this.showReceiver = true
        if (res.data.errorCode == '0x0') {
          const result = res.data.result
          this.total = result.total
          this.queryRlist = result.list
          if (result.list.length > 0) {
            let tempArr = []
            result.list.map((item, index) => {
              tempArr[item.peerId] = item
            })
            this.$nextTick(() => {
              this.socketObj = tempArr
            })
            this.sendWebsocket(result.list)
            if (this.imgTimer) {
              clearInterval(this.imgTimer)
            }
            this.imgTimer = setInterval(() => {
              this.thumbnailUpdate()
            }, 2000)
          }
        } else {
          this.$message({
            type: 'info',
            message: res.data.errorInfo
          })
        }
      }).catch(() => {
        this.loading = false
        this.showReceiver = true
      })
    },
    // 创建websocket
    createMsgWebsocket () {
      this.sendMsgWebsocket('VolumeChart', '2152867840', '200', 200) // 获取音量信息
      this.sendMsgWebsocket('SlotInfo', '2152865792', '101', 10000) // 获取slot信息
      this.sendMsgWebsocket('ReceiverData', '2152857600', '101', 1000) // 获取err and quality信息
      this.sendMsgWebsocket('CurrentRInfo', '2152857600', '102', 1000) // 获取err and quality信息
      this.sendMsgWebsocket('SetInfo', '2152866048', '101', 1000) // 获取err and quality信息
    },
    // 获取设备实时信息
    sendMsgWebsocket (type, categoryId, operationType, time) {
      let typeVal = ''
      if (type != 'VolumeChart') typeVal = `${type[0].toLowerCase()}${type.slice(1, type.length)}`
      this.pageRWebsocket.send({
        message: {
          // type: typeVal ? typeVal : type,
          categoryId: categoryId,
          operationType: operationType,
          rid: this.liveRids.join(',')

        },
        callBackPass: 'rInfo', // 用以回调函数的正确接收
        time: time || 1000,
        key: typeVal || type,
        success: res => {
          this.updateDeviceDetailData(`deviceList${type}Data`, `DEVICELIST_${type.toUpperCase()}DATA`, res)
        }
      })
    },
    // 更新音柱和设备详情信息
    updateDeviceDetailData (deviceData, fn, data) {
      let deviceDetailArr = []
      let deviceDetailIdArr = []
      this.$store.state.common[deviceData].map((v, i) => {
        if (this.liveRids.includes(v.rid)) {
          deviceDetailArr.push(v)
          deviceDetailIdArr.push(v.rid)
        }
      })
      if (deviceDetailIdArr.includes(data.rid)) {
        deviceDetailArr[deviceDetailIdArr.indexOf(data.rid)] = data
      } else {
        deviceDetailArr.push(data)
      }
      this[fn](deviceDetailArr)
    },
    // 更新websocket参数
    updateParams () {
      if (this.liveRids.length > 0) {
        this.updateSocketParams('VolumeChart', '2152867840', '200')
        this.updateSocketParams('slotInfo', '2152865792', '101')
        this.updateSocketParams('receiverData', '2152857600', '101')
        this.updateSocketParams('currentRInfo', '2152857600', '102')
        this.updateSocketParams('setInfo', '2152866048', '101')
      }
    },
    stopupdateParams () {
      if (this.$createWebsocket.pageRWebsocket) {
        this.$createWebsocket.pageRWebsocket('stop', ['VolumeChart', 'slotInfo', 'receiverData', 'currentRInfo', 'setInfo']) // 停止websocket
      }
    },
    // 更新websocket参数
    updateSocketParams (type, categoryId, operationType) {
      const message = {
        categoryId: categoryId,
        operationType: operationType,
        rid: this.liveRids.join(',')
      }
      if (this.pageRWebsocket.sendMsgs && this.pageRWebsocket.sendMsgs[type] && this.pageRWebsocket.sendMsgs[type].message) {
        this.pageRWebsocket.sendMsgs[type].message = message
      }
      if (this.pageRWebsocket.sendMsgs && this.pageRWebsocket.sendMsgs[type]) {
        this.pageRWebsocket.sendMsgs[type].callBackPass = 'rInfo'
      }
    },
    // 更新缩略图
    thumbnailUpdate () {
      let peerIds = []
      this.peerIds.map(v => {
        if (v) peerIds.push(v)
      })
      if (!peerIds.length) return
      this.axios.post(`${location.protocol}//${this.$store.state.common.baseUrl.blobUrl.split('/')[0]}/thumbnailVersion`, peerIds, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
        .then(res => {
          this.imgUrlTime.last = this.imgUrlTime.current
          this.imgUrlTime.current = res.data
        }).catch(() => {})
    },
    sendWebsocket (data) {
      var sendParam = []
      this.peerIds = []
      if (data) {
        data.forEach(item => {
          sendParam.push(item.peerId)
        })
      }
      this.peerIds = sendParam
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'taskInfo_R_W') // 停止websocket taskInfo_R_W这个可以自定义 发送什么返回什么
      }
      this.pageRequest.send({
        message: {
          type: 'taskInfo',
          module: 'taskInfo_R_W',
          data: { peerIds: this.peerIds.join(',') }
        },
        time: 1000,
        key: 'taskInfo_R_W',
        success: res => {
          var results = res.result
          this.liveRids = []
          if (results.length > 0) {
            var thisD = []
            results.map(item => {
              thisD[item.peerId] = item
              // live的rid
              if (item.status == 2 && item.type.toLowerCase() == 'r') this.liveRids.push(item.peerId)
            })
            // 更新websocket参数
            this.updateParams()
            this.socketObj = thisD
          }
          let newParam = []
          if (this.queryRlist) {
            this.queryRlist.forEach(item => {
              newParam.push(item.peerId)
            })
            // newParam.push(this.$store.state.common.currentRid);
          }
          this.peerIds = newParam
          var taskInfoMsg = {
            type: 'taskInfo',
            module: 'taskInfo_R_W',
            data: { peerIds: this.peerIds.join(',') }
          }
          this.pageRequest.sendMsgs.taskInfo_R_W.message = taskInfoMsg
          // }
        }
      })
    },
    //   绑定推拽事件
    bindingDrag () {
      this.BindDragMethod = new DragMethod()
      this.BindDragMethod.create({
        // 拖到的区域
        dropZones: document.getElementById('drag-wrap'),
        // 被拖动的元素
        from: [
          document.querySelectorAll('.sourceItemBox')
        ],
        dropEnd: (e) => {
          // e 落下的盒子
          const BoxStartTime = e.target.dataset.info
          if (BoxStartTime) {
            const targetInfo = JSON.parse(BoxStartTime)
            if (targetInfo && targetInfo.id) {
              this.setDomInfo = targetInfo
              this.setInput = targetInfo.type == 'all'
              this.oneSource = false
            }
          }
          //   被拖拽元素数据
          const TargetEvent = JSON.parse(this.BindDragMethod.dragElement.dataset.info)
          if (TargetEvent) {
            this.dragDomInfo = TargetEvent
            this.dragDomInfo.update = 0
          }
          if (this.setInput) {
            this.inputName = TargetEvent.name
            this.prepared = false
          }
          if (!isNaN(BoxStartTime) && TargetEvent) {
            //  判断是重新创建还是修改  模板id又值则为修改
            if (TargetEvent.templateId) {
              //   修改事件
              this.DragUpdateEvent(
                BoxStartTime,
                BoxStartTime + 30 * 60 * 1000,
                TargetEvent,
                this.BindDragMethod.dragElement.dataset.index
              )
            } else {
              //   创建事件
              this.DragCreateEvent(
                BoxStartTime,
                BoxStartTime + 30 * 60 * 1000,
                TargetEvent
              )
            }
          }
        }
      })
    },
    handleCurrentChange (val) {
      this.params.pageNum = val
      this.getReceiverList()
    },
    handleSizeChange (val) {
      this.params.pageSize = val
      this.params.pageNum = 1
      this.getReceiverList()
    },
    handleCheckAllChange (val) {
      // 全选框的回调事件
      this.checkedCities = val ? cityOptions : []
      this.isIndeterminate = false
      if (this.checkAll) {
        // 判断这个全选的框是否被选中然后给接口传参
        this.params.offline = true // queryRlist的接口参数
        this.params.online = true // queryRlist的接口参数
        this.params.live = true // queryRlist的接口参数
        this.params.scope = '1'
      } else {
        this.params.offline = false
        this.params.online = false
        this.params.live = false
        this.params.scope = '0'
      }
      this.params.pageNum = 1
      this.getReceiverList()
    },
    handleCheckedCitiesChange (value) {
      // 子组的筛选框
      const checkedCount = value.length
      this.checkAll = checkedCount === this.cities.length
      if (this.checkAll) {
        this.params.scope = '1'
      } else {
        this.params.scope = '0'
      }
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length
      const statusArr = value // 通过索引去把每次筛选的条件传给queryRlist这个接口
      if (statusArr.indexOf('live') > -1) {
        // 控制live的参数
        this.params.live = true
      } else {
        this.params.live = false
      }

      if (statusArr.indexOf('online') > -1) {
        // 控制online的参数
        this.params.online = true
      } else {
        this.params.online = false
      }

      if (statusArr.indexOf('offline') > -1) {
        // 控制offline的参数
        this.params.offline = true
      } else {
        this.params.offline = false
      }
      this.params.pageNum = 1
      this.getReceiverList()
    }
  },
  beforeDestroy () {
    if (this.imgTimer) {
      clearInterval(this.imgTimer)
    }
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', 'taskInfo_R_W') // 停止websocket taskInfo_R_W这个可以自定义 发送什么返回什么
    }
    if (this.$createWebsocket.pageRWebsocket) {
      this.$createWebsocket.pageRWebsocket('stop', ['VolumeChart', 'slotInfo', 'receiverData', 'currentRInfo', 'setInfo']) // 停止websocket
    }
  }
}
</script>

<style scoped lang="less">
.content {
  width: 100%;
  height: 100% !important;
  // max-width: 1920px;
  margin: 0 auto;
  .device-search {
    width: 100%;
    height: 32px;
    line-height: 32px;
    .title {
      display: inline-block;
      font-size: 16px;
      color: #666;
    }
    .statusBox {
      float: left;
      .statusName {
        float: left;
        font-size: 14px;
        font-weight: 500;
        color: rgba(102, 102, 102, 1);
        padding: 0 10px;
      }
      .allStatus {
        margin-right: 10px;
      }
      .allStatus,
      .statusCheckBox {
        float: left;
      }
    }
    .pages {
      height: 30px;
      line-height: 30px;
      width: fit-content;
      float: right;
      margin-right: 20px;
      .drag_content {
        width: 250px;
        height: 30px;
        margin-right: 20px;
        ::v-deep .el-input__inner {
          height: 30px;
        }
        ::v-deep .el-input__inner::-webkit-input-placeholder {
          color: #8d8d8d;
        }
      }
      .operate-btn {
        height: 30px;
        line-height: 30px;
        padding: 0 20px;
        margin-right: 10px;
      }
    }
  }
  .listBox {
    height: calc(100% - 94px);
  }
  .dataEmpty {
    width: 100%;
    height: 100%;
    text-align: center;
    .empty {
      line-height: 60px;
      color: #666;
      font-size: 16px;
      position: fixed;
      left: 50%;
      top: 39%;
      transform: translateX(-50%);
    }
  }
}
::v-deep .el-button.is-disabled:hover,
.el-button.is-disabled:focus {
  background: none;
  border-color: rgba(102, 102, 102, 1);
}
.el-button.is-disabled {
  color: #dbdbdb;
}
::v-deep .el-checkbox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: none;
  border-color: rgba(102, 102, 102, 1);
}
::v-deep
  .el-checkbox
  .el-checkbox__input.is-indeterminate
  .el-checkbox__inner::before {
  display: none;
}
::v-deep .el-checkbox .el-checkbox__inner {
  border: 2px solid rgba(102, 102, 102, 1);
  background: none;
}
@media (max-width: 1919px) {
  .listBox {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
