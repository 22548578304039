
<template>
  <el-dialog
    width="650px"
    class="message-dialog"
    :visible="info.show"
    :close-on-click-modal="false"
    :title="$t('lang.warningTitle')"
    @close="cancel">
    <ul
      class="error-box"
      v-if="info.errorList && info.errorList.length > 0">
      <li
        v-for="item in info.errorList"
        :key="item.id">
        {{ `${item.name}(${item.peerId})：` }}
        <span class="red">{{item.errorInfo}}</span>
      </li>
    </ul>
  </el-dialog>
</template>
<script>
export default {
  props: {
    info: {
      type: Object,
      default: function () {
        return {
          show: true
        }
      }
    }
  },
  components: {
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    cancel () {
      this.info.show = false
    }
  }
}
</script>
<style lang="less" scoped>
.message-dialog {
  text-align: left;
  ::v-deep .el-dialog {
    .el-dialog__body {
      min-height: 250px;
      max-height: 350px;
      overflow-y: scroll;
      padding: 30px 20px 50px;
      .red {
        color: #eb4040;
      }
    }
  }
}
</style>
