<template>
  <div class="container peerListBox">
    <div class="sourceModule">
      <search-input
        :value="params.condition"
        :placeholder="$t('lang.searchTText')"
        :callback="search"></search-input>
    </div>
    <div class="filterModule">
      <div class="item f_left">
        <!-- status 筛选 -->
        <div class="selectBox">
          <drop-menu-select
            :value="params.condition"
            :data="statusData"
            :title="$t('lang.status')"
            :callback="changeDropmenuFilter"></drop-menu-select>
        </div>
      </div>
      <!-- bookmark 筛选 -->
      <div class="item f_left">
        <book-mark
          :callback="this.filterInfo"
          :from="{Unclassified:true}"
          :defaultKeys="defaultBookmark"></book-mark>
      </div>
    </div>

    <div
      class="sourceListModule"
      ref="sourceListModule">
      <div class="btnBox clearfix">
        <div
          class="f_right m-r-5"
          @click="showModule('right')">
          <i
            class="iconfont sourceInfoIcon"
            :class="{active:infoIcon}"></i>
        </div>
        <div
          class="f_right m-r-5"
          @click="showModule('left')">
          <i
            class="iconfont sourceListIcon"
            :class="{active:listIcon}"></i>
        </div>
        <div class="selectFilter f_right m-r-5 select-box">
          <select-menu
            :data="selectBySort"
            :callback="changeSort"
            :selectVal="selectSort"></select-menu>
        </div>
      </div>
      <!-- 缩略图显示区域 -->
      <div
        class="s-l-array"
        :class="{'hide':showArray}"
        @scroll="onScroll('repeat')">
        <div class="s-l-ul list-unstyled row">
          <div class="infoBox">
            <div
              class="itemBox f_left"
              v-for="(itm, idx) in gridList"
              :key="idx">
              <source-item
                :imgUrlTime='imgUrlTime'
                :sourceItem='socketList[itm.peerId]'
                :imgItem='thumnailImgObj[itm.peerId]'
                :typeItem="itm"
                @mouseenterCall="showDeviceInfo"
                @mouseleaveCall="showDeviceInfo"></source-item>
            </div>
          </div>
          <div
            class="showMoreBtn"
            v-if="showBtn()"
            @click="getGridList()">{{ $t('lang.loadMore') }}
          </div>
        </div>
      </div>
      <!-- 设备详细信息显示区域 -->
      <div
        class="s-l-info"
        :class="{'hide':showInfo}"
        @scroll="onScroll('list')">
        <div
          class="itemBox"
          v-for="(itm, idx) in gridList"
          :key="idx">
          <source-info
            :sourceItem='socketList[itm.peerId]'
            :typeItem='itm'></source-info>
        </div>
        <div
          class="showMoreBtn"
          v-if="showBtn()"
          @click="getGridList()">{{ $t('lang.loadMore') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SearchInput from '@/components/Search'
import DropMenuSelect from '@/components/dropMenuSelect'
import {
  StatusSelect,
  SelectBySorts
} from '@/config'
import BookMark from '@/components/bookMark'
import SelectMenu from '@/components/SelectMenu'
import { mapState } from 'vuex'
import '@/assets/css/peerList.css'
import { adAnalytice } from '@/assets/js/googleAnalyze/advanceControl'
import SourceItem from './sourceItem'
import Bus from '@/assets/js/vueBus'
import SourceInfo from './sourceInfo'

export default {
  name: 'PeerList',
  data () {
    return {
      name: '', // 搜索框输入值
      statusData: StatusSelect,
      selectBySort: SelectBySorts,
      bookmark: '',
      sourceList: [],
      socketList: {},
      params: {
        living: true,
        online: true,
        offline: true,
        pack: false,
        anywhere: false,
        gridPack: true,
        gridSdi: true,
        localSdi: false,
        ext: false,
        pageNum: 1,
        pageSize: 18,
        condition: '',
        bookmardIds: 'all',
        unClassified: true,
        token: false,
        onlineFirst: true,
        liveFirst: false,
        globalGrid: true,
        gridViaToken: true,
        noCamera: true,
        ignoreSearchKey: true
      },
      defaultBookmark: { // 默认bookmark选中的值
        bookmardIds: [],
        token: true,
        unClassified: true
      },
      sourceSocket: {},
      loading: false,
      noMore: false,
      selectSort: '',
      deviceInfoFlag: false,
      deviceInfoObj: {
        showInfoObj: {
          showName: false,
          showPlatform: false,
          showVersion: false,
          showLivePeerId: false,
          showBattery: false,
          showInput: false,
          showFormat: false,
          showCodec: false,
          showUploader: false,
          showTitle: false,
          showCheckStatus: false,
          showEncoder: false,
          showDirect: false,
          showRelay: false,
          showChannel: false
        },
        contentInfoObj: {},
        gridChannels: []
      },
      selectSourceStatus: null,
      selectSourceId: null,
      selectSourceType: null,
      showArray: false,
      showInfo: true,
      infoIcon: false,
      listIcon: true,
      errorImg: 'this.src="' + require('@/assets/img/logot.png') + '"',
      versionArray: [],
      sortTypeFlag: false,
      haveSortByType: false,
      haveSortByName: false,
      sortNameFlag: false,
      nameArray: [],
      deviceInfoTimer: null,
      thumImgObj: {}, // use it to update thumbnail
      thumnailImgObj: {},
      gridList: [],
      gridTotal: 0,
      gridIdList: [],
      allSourceStrList: [],
      scrollTimer: null,
      startIndex: 0,
      availbleIdList: [],
      refreshImgTimer: null, // 临时记录的用于过滤后刷新
      imgUrlTime: {
        current: {},
        last: {}
      },
      imgTimer: null,
      curDeviceList: []
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      pageRWebsocket: state => state.websocket.pageRWebsocket,
      pageRequest: state => state.websocket.pageRequest
    })
  },
  async created () {
    await this.getUserBehavior()
    // 初始化所有搜索数据
    this.initSearchData()
    this.getGridList('addPageNum', 'sendSocket')
    if (this.imgTimer) {
      clearInterval(this.imgTimer)
    }
    this.imgTimer = setInterval(() => {
      if (!this.curDeviceList.length) return
      this.thumbnailUpdate()
    }, 2000)
  },
  methods: {
    getGridList (addPageNum, sendSocket) {
      if (!addPageNum) this.params.pageNum++

      this.axios.post('/ccp/tvucc-user/userDevice/pageUserSource', this.params, {
        contentType: 'application/json;charset=UTF-8'
      }).then(res => {
        if (res.data.errorCode == '0x0') {
          this.gridIdList = []
          const result = res.data.result.list ? res.data.result.list : []
          this.gridTotal = res.data.result.total
          this.gridList = this.gridList.concat(result)
          this.gridIdList = this.gridList.map(item => {
            return item.peerId
          })
          Bus.$emit('bindDragEvent')
          if (sendSocket) this.sendSocket()
          if (result.length < this.params.pageSize) this.noMore = true
          if (this.gridList.length) {
            result.map(v => {
              this.$set(this.socketList, v.peerId, v)
              this.thumnailImgObj[v.peerId] = {}
            })
            this.getAvailbleId(this.gridList)
            this.updateThumnail()
          }
        }
      }).catch(() => {})
    },
    sendSocket () {
      this.pageRequest.send({
        message: {
          type: 'taskInfo',
          module: 'taskInfo_T_S',
          data: { peerIds: this.gridIdList }
        },
        time: 1000,
        key: 'taskInfo_T_S',
        success: (res) => {
          const sourceList = res.result
          sourceList.forEach(item => {
            this.$set(this.socketList, item.peerId, item)
          })
          this.updateThumnail()
          // 更新维护发送的sourceList
          const taskInfoMsg = {
            type: 'taskInfo',
            module: 'taskInfo_T_S',
            data: { peerIds: this.gridIdList.join(',') }
          }
          this.pageRequest.sendMsgs.taskInfo_T_S.message = taskInfoMsg
        }
      })
    },
    // 更新缩略图
    thumbnailUpdate () {
      let peerIds = []
      this.gridList.map(v => {
        peerIds.push(v.peerId.toLowerCase())
      })
      if (!peerIds.length) return
      this.axios.post(`${location.protocol}//${this.$store.state.common.baseUrl.blobUrl.split('/')[0]}/thumbnailVersion`, peerIds, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
        .then(res => {
          this.imgUrlTime.last = this.imgUrlTime.current
          this.imgUrlTime.current = res.data
        }).catch(() => {})
    },
    // 是否显示加载下一面的按钮
    showBtn () {
      const totalNumber = this.gridTotal
      const pageNumber = this.params.pageNum
      if (totalNumber - (this.params.pageSize * pageNumber) >= 1) {
        return true
      } else {
        return false
      }
    },
    updateThumnail () {
      const _this = this
      Object.keys(this.socketList).map((v, i) => {
        if (this.availbleIdList.includes(v.toLowerCase())) {
          _this.socketList[v].showThum = true
        } else {
          _this.socketList[v].showThum = false
        }
        _this.thumnailImgObj[v] = _this.socketList[v]
      })
    },
    onScroll (type) {
      if (this.scrollTimer) clearTimeout(this.scrollTimer)
      this.scrollTimer = setTimeout(() => {
      }, 1000)
    },
    search (val) {
      // 埋点统计
      adAnalytice('adSearch')

      // 搜索
      this.params.condition = val
      this.filterInfo()
    },
    // 过滤type
    changeDropmenuFilter (data, all, list, typeVal, isSelectAll) {
      // 埋点统计
      const lastParams = JSON.parse(JSON.stringify(this.params))
      let type = 'adStatus'
      if (typeof data.pack == 'boolean') { // 选择的是type
        type = 'adType'
      }
      const params = all ? Object.assign(data, all) : Object.assign(data)
      adAnalytice(type, params, lastParams)

      Object.keys(data).map(v => {
        this.params[v] = data[v]
      })
      this.filterInfo()
    },
    getChangeType (newParams, oldParams) {
      for (const k in newParams) {
        const newVal = newParams[k]
        const oldVal = oldParams[k]
        if (newVal !== oldVal) {
          return k
        }
      }
    },
    changeSort (val) { // 排序
      // 埋点统计
      adAnalytice('adSort', val)

      this.params.pageNum = 1
      this.params.onlineFirst = val == 'onlineFirst'
      this.params.liveFirst = val == 'liveFirst'
      this.filterInfo()
    },
    async filterInfo (bookMark, rObj, str) {
      this.gridList = []
      this.noMore = false
      this.bookmark = bookMark || this.bookmark
      this.params.pageNum = 1
      this.pageNum = 1
      this.params.pageSize = this.params.pageSize
      this.params.condition = this.params.condition
      this.params.bookmardIds = this.bookmark ? this.bookmark.bookmardIds : this.params.bookmardIds
      this.params.unClassified = this.bookmark ? this.bookmark.unClassified : this.params.unClassified
      this.params.token = this.params.anywhere
      this.params.globalGrid = this.params.gridSdi || this.params.gridPack
      this.params.gridViaToken = this.params.gridSdi || this.params.gridPack
      delete this.params.allType
      await this.getGridList('addPageNum')
      this.setUserBehavior()
      this.getAllSourceIdByOperation()
      if (this.refreshImgTimer) clearTimeout(this.refreshImgTimer)
      this.refreshImgTimer = setTimeout(() => {
        this.updateThumnail()
      }, 1000)
    },
    setUserBehavior () {
      const params = {
        pn: 'gridManage',
        content: JSON.stringify(this.params),
        remark: localStorage.getItem('userId')
      }
      this.axios.post('/ccp/tvucc-user/userBehavior/set', params)
        .then(res => {
        }).catch(() => {})
    },
    async getUserBehavior () {
      const params = {
        pn: 'gridManage',
        serverName: 'ccp',
        remark: localStorage.getItem('userId')
      }
      await this.axios.get('/ccp/tvucc-user/userBehavior/get', {
        params
      }).then(res => {
        if (res.data.result) {
          const userId = localStorage.getItem('userId')
          if (res.data.result[userId]) {
            const obj = JSON.parse(res.data.result[userId])
            obj.pageNum = 1
            this.params = obj
          }
        }
      }).catch(() => {})
    },
    showModule (key) {
      // 埋点统计
      const type = key == 'left' ? 'ThumbnailList' : 'SourceList'
      adAnalytice('adList', type)

      if (key == 'left') {
        this.showArray = false
        this.showInfo = true
        this.infoIcon = false
        this.listIcon = true
        this.haveSortByType = false
        this.haveSortByName = false
      } else {
        this.showArray = true
        this.showInfo = false
        this.infoIcon = true
        this.listIcon = false
      }
    },
    // 保存所选择的的tid
    setSourceId (obj, event) {
      // 埋点统计
      adAnalytice('adListSelectSource')
      if (obj.type == 'GLink') {
        this.$store.state.selectSourceId = obj.peerId
      } else {
        this.$store.state.selectSourceId = obj.IdHex.toLowerCase()
      }
      this.$store.state.selectSourceName = obj.Name
      this.selectSourceStatus = obj.Status
      this.$store.state.selectSourceStatus = obj.status
      this.selectSourceType = obj.type
      this.$store.state.selectSourceType = obj.type
    },
    // 排序
    sort (key) {
      // 先关闭所有条件排序在根据所选择的进行排序
      this.isHaveReverse = false
      this.haveSortByType = false
      this.haveSortByName = false

      switch (key) {
        case 'name':
          this.haveSortByName = true
          this.sortByName('click')
          break
        case 'type':
          this.haveSortByType = true
          this.sortByType('click')
          break
      }
    },
    // 根据名字字母排序
    sortByName (key) {
      let nameList = []
      if (key === 'click') this.sortNameFlag = !this.sortNameFlag
      if (this.haveSortByName) {
        if (this.sortNameFlag) {
          nameList = this.sourceList.sort(function (data1, data2) {
            if (
              /^\d/.test(data1.sourceName) ^ /^\D/.test(data2.sourceName)
            ) {
              return data1.sourceName > data2.sourceName ? 1 : data1.sourceName == data2.sourceName ? 0 : -1
            }
            return data1.sourceName > data2.sourceName ? -1 : data1.sourceName == data2.sourceName ? 0 : 1
          })
          this.sourceList = nameList
          this.nameArray = nameList
          this.isHaveReverse = false
        } else {
          if (!this.isHaveReverse) {
            this.isHaveReverse = true
            this.sourceList = this.nameArray.reverse()
          } else {
            this.sourceList = this.nameArray
          }
        }
      }
    },
    // 根据类型排序pack>grid>ext
    sortByType (key) {
      // 只有在主动点击TYPE排序的时候才更改值.
      if (key === 'click') this.sortTypeFlag = !this.sortTypeFlag
      if (this.haveSortByType) {
        if (this.sortTypeFlag) {
          this.sourceList.reverse()
        }
      }
    },
    // 显示设备的信息
    showDeviceInfo (event, item, key) {
      if (key === 'show') {
        if (this.deviceInfoTimer) clearInterval(this.deviceInfoTimer)
        this.deviceInfoTimer = setTimeout(
          function () {
            const x = event.x
            const y = event.y
            this.deviceInfoFlag = true
            $('.deviceInfoBox').css('top', y)
            $('.deviceInfoBox').css('left', x)
            this.deviceInfoObj.contentInfoObj.name = item.Name || item.name
            this.deviceInfoObj.contentInfoObj.id = item.IdHex || item.peerId
            this.deviceInfoObj.contentInfoObj.rName = item.LiveName
            this.deviceInfoObj.contentInfoObj.status = item.Status
            this.deviceInfoObj.contentInfoObj.workMode = this.$store.state.GetKernelStateObj.WorkMode
            // 如果状态是0不在线的话就只显示那么
            Object.keys(this.deviceInfoObj.showInfoObj).forEach(
              function (key) {
                this.deviceInfoObj.showInfoObj[key] = true
              }.bind(this)
            )
            // 根据状态显示要显示的内容
            if (item.status != 0) {
              this.showGridInfo(item)
            }
          }.bind(this),
          500
        )
      } else {
        if (this.deviceInfoTimer) clearInterval(this.deviceInfoTimer)
        if (event.relatedTarget) {
          if (event.relatedTarget.className == 'deviceInfo') return
          this.deviceInfoFlag = false
          this.deviceInfoObj.name = ''
          this.deviceInfoObj.platform = ''
          this.deviceInfoObj.version = ''
          this.deviceInfoObj.live = ''
          this.deviceInfoObj.battery = ''
          this.deviceInfoObj.input = ''
          this.deviceInfoObj.format = ''
          this.deviceInfoObj.codec = ''
          this.deviceInfoObj.id = ''
          this.deviceInfoObj.status = ''
        }
      }
    },
    // 当有过操作的时候需要重新计算allsourceId
    getAllSourceIdByOperation () {
      // 现获取展开的id list
      this.getAvailbleId()
      // 更新视图
      this.updateThumnail()
    },
    // 获取展开的source id array
    getAvailbleId () {
      this.allSourceStrList = []
      this.curDeviceList = []
      this.gridList.forEach(v => {
        const sourceId = v.IdHex || v.peerId
        this.allSourceStrList.push(sourceId)
        this.curDeviceList.push(JSON.parse(JSON.stringify(v)))
      })
      this.availbleIdList = this.allSourceStrList.slice(this.startIndex, this.allSourceStrList.length).slice(0, 18)
    },
    showGridInfo (item) {
      this.deviceInfoObj.contentInfoObj.IsCheckStatus = item.IsCheckStatus == true ? 'True' : 'False'
      var _str = ''; var direct = 'True'; var XAlive = -1
      // 转换类型
      if (item.GridEncoderStatus == -1) {
        _str = 'Unknown'
      } else if (item.GridEncoderStatus == 0) {
        _str = 'Disabled'
      } else if (item.GridEncoderStatus == 1) {
        _str = 'NoInput'
      } else {
        _str = 'Encoding'
      }
      // 转换大小写
      if (item.IsDirectConnection == true) {
        direct = 'True'
      } else {
        direct = 'False'
      }
      // 转换xalive
      if (item.XAlive == -1) {
        XAlive = 'UNKNOWN'
      } else if (item.XAlive == 0) {
        XAlive = 'NO_CONNECTION'
      } else if (item.XAlive == 1) {
        XAlive = 'DEAD'
      } else if (item.XAlive == 2) {
        XAlive = 'ALIVE'
      }
      this.deviceInfoObj.contentInfoObj.GridEncoderStatus = _str
      this.deviceInfoObj.contentInfoObj.IsDirectConnection = direct
      this.deviceInfoObj.contentInfoObj.XAlive = XAlive
      this.deviceInfoObj.gridChannels = item.AdditionalChannels
      this.deviceInfoObj.showInfoObj.showName = false
      this.deviceInfoObj.showInfoObj.showCheckStatus = false
      this.deviceInfoObj.showInfoObj.showEncoder = false
      this.deviceInfoObj.showInfoObj.showDirect = false
      this.deviceInfoObj.showInfoObj.showRelay = false
      this.deviceInfoObj.showInfoObj.showChannel = false
    },
    initSearchData () {
      this.statusData.map((v, k) => {
        const obj = v
        obj.isChecked = this.params[v.value] || false
        this.$set(this.statusData, k, obj)
      })
      this.selectSort = this.$options.filters.FORMATSORT(this.params)
      // bookmark
      this.defaultBookmark.bookmardIds = this.params.bookmardIds ? this.params.bookmardIds == 'all' ? ['all'] : this.params.bookmardIds.split(',') : []
      this.params.unClassified && this.defaultBookmark.bookmardIds.push('unClassified')
      this.params.token && this.defaultBookmark.bookmardIds.push('token')
    }
  },
  components: {
    BookMark,
    SearchInput,
    DropMenuSelect,
    SelectMenu,
    SourceItem,
    SourceInfo
  },
  beforeDestroy () {
    if (this.imgTimer) {
      clearInterval(this.imgTimer)
    }
  }
}
</script>
<style scoped lang="less">
@import '../../assets/css/rcAdaptive/peerList.less';
.imgChange {
  width: 50px;
  height: 25px;
}
.sourceListModule {
  height: calc(100% - 100px);
  .infoBox {
    overflow: hidden;
  }
}
.showMoreBtn {
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  background: #444;
}
.s-l-info {
  height: 100%;
}
.showMoreBtn:hover {
  background: #333;
}

.peerListBox {
  float: left;

  .filterModule {
    display: flex;

    .item {
      flex: 1;
      position: relative;

      .selectBox {
        width: calc(100% - 10px);
      }
    }

    .item:first-child {
      .selectBox {
        margin-left: 0;
      }
    }

    ::v-deep .tree-btn {
      height: 32px;
      line-height: 32px;

      &.enBtn {
        padding: 0 5px;
      }

      @media screen and (min-width: 760px) and (max-width: 1279px) {
        &.enBtn {
          padding: 0 20px;
        }
      }
    }
  }
}

.searchBox {
  ::v-deep .el-input__inner {
    color: #fff;

    &::-webkit-input-placeholder {
      color: #666;
    }
  }
}

.selectFilter {
  width: 120px;

  ::v-deep .el-select .el-input__inner {
    background: #444;
    height: 26px;
    line-height: 26px;
  }

  ::v-deep .el-input__suffix {
    line-height: 26px;

    .el-input__icon {
      line-height: 26px;
    }
  }
}

#videoBtn,
#searchInput {
  width: 100%;
  height: 100%;
  outline: none;
}

::v-deep .el-button {
  width: 100%;
}

::v-deep .el-dropdown-menu {
  width: 100px;
}

::v-deep .el-dropdown-menu__item {
  padding: 0 18px;

  .checkBox {
    margin: 2px 5px 0 0;
  }
}

#videoBtn {
  border: 0;
}

.f-s-b {
  background: #252525;
  border: none;
  padding: 3px 25px;
  border-radius: 5px;
}

.f-s-b.focus,
.f-s-b:focus,
.f-s-b:hover {
  color: #fff;
}

.peerBox {
  min-width: 92px;
}

.dropdown-menu {
  min-width: -webkit-fill-available;
  background: #252525;
}

.dropdown-menu > li > a {
  color: #fff;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  background: none;
}

.dropdown-menu > li:hover {
  background: rgba(57, 170, 80, 0.25);
}

.f-s-m {
  margin-right: 13px;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 300px) {
  .container {
    color: #dbdbdb;
    background-color: #252525;
    width: 100%;
    padding-right: 12px;
  }
}

@media (min-width: 300) and (max-width: 600px) {
  .container {
    width: 100%;
  }
}

.table-hover > tbody > tr:hover {
  background-color: rgba(57, 170, 80, 0.25);
}

.table > tbody > tr > td {
  line-height: 1.65;
  text-align: left;
}

.table > thead > tr > th {
  text-align: left;
}

.dropdown-menu > li > a {
  display: inline-block;
  padding: 3px 5px;
}

.f-f-m .dropdown-menu {
  min-width: 145px;
}

.imgBox {
  background: #000;
  height: 65px;
  min-height: 51px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
}

.title-box {
  padding-left: 10px;
  color: #cccccc;
}

.sourceListModule .el-collapse-item {
  margin-bottom: 10px;
}

::v-deep .el-collapse-item__wrap {
  background: none;
  border-bottom: 0;
  margin-top: 8px;
}

::v-deep .el-icon-arrow-right:before {
  content: '\E613';
  font-size: 16px;
  color: #ccc;
}

#app {
  margin-top: 0;
}

.addSourceOl input {
  width: inherit;
  height: inherit;
  width: 215px;
  color: #333;
}

.s-l-ul {
  padding-bottom: 100px;
}

.s-l-ul div.packBox:last-child {
  margin-bottom: 0;
}

.voipBox {
  cursor: pointer;
  display: inline-block;
  padding: 2px 3px;
  border-radius: 2px;
  position: absolute;
  right: 2px;
  bottom: 4px;
  font-size: 12px;
  line-height: 12px;
}

.voipBox[disabled] {
  background: gray;
}

.sourceListModule {
  padding: 0;

  .line {
    text-decoration: underline;
  }

  .btnBox {
    margin: 7px 0 5px;
  }

  .m-r-5 {
    margin-right: 10px;
  }

  .sourceAddIcon {
    position: relative;
    top: 3px;
  }

  .editIcon {
    display: block;
  }
}

.switch {
  width: 11px;
  height: 11px;
  display: inline-block;
  border-radius: 50%;
  margin-top: 5px;
  float: right;
  margin-right: 5px;
}

.deviceInfo {
  position: absolute;
  background: #444;
  z-index: 1;
  text-align: left;
  border: 1px solid #fff;
  padding: 5px;
  width: 450px;
  font-size: 13px;
}

.deviceInfo div span:nth-child(1) {
  width: 20%;
  display: inline-block;
}

.deviceInfo div span:nth-child(2) {
}

#channels div span:nth-child(1) {
  width: auto;
  padding-left: 10px;
}

.option {
  overflow: hidden;
}

.quality a {
  color: #fff;
}

.green {
  background: #33aa50;
}

.red {
  background: red;
}

.deviceInfoBox {
  position: fixed;
  z-index: 2;
}

@media screen and (min-width: 500px) and (max-width: 759px) {
  .sourceListModule {
    margin-bottom: -21px;
  }
}
</style>
