<template>
  <div class="bookmark-container">
    <el-breadcrumb
      class="breadcrumb"
      separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        v-for="(itm,index) in breadcrumb"
        :key="itm.id">
        <template>
          <span @click="backupStep(itm, index)">{{itm.name}}</span>
        </template>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-module">
      <search-input
        :value="condition"
        :placeholder="breadcrumb.length == 1? $t('lang.searchReceivers'):`${$t('lang.searchIn')} '${breadcrumb[breadcrumb.length - 1].name}'`"
        :callback="search"></search-input>
    </div>
    <div class="line"></div>
    <div
      class="bookmark-box"
      v-if="bookmarkList.length > 0">
      <div class="title">{{ $t('lang.bookmarks') }}</div>
      <Pagination
        @current-change="handleCurrentChange"
        :current-page="params.pageNum"
        :pageSizes="[10, 15, 20, 50, 100]"
        :pageSize="params.pageSize"
        :total="total"
        @size-change="handleSizeChange"
        layout="prev, pageF, next, pageL, nowSize, jumper">
      </Pagination>
      <ul
        class="bookmark-list"
        v-loading='loading'
        element-loading-background="rgba(0, 0, 0, 0.8)">
        <bookmark-item
          v-for="item in bookmarkList"
          :key="item.id"
          :data="item"
          :dragDomInfo="setDomId == item.id? dragDomInfo : null"
          @changeBookmark="(obj) => getBookMarkList(obj)">
        </bookmark-item>
      </ul>
      <div class="line"></div>
    </div>
  </div>
</template>
<script>
import Bus from '@/assets/js/vueBus'
import SearchInput from '@/components/Search'
import Pagination from '@/components/Pagination'
import BookmarkItem from './bookmarkItem'
export default {
  props: {
    setDomId: {
      type: String,
      default: ''
    },
    dragDomInfo: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      params: {
        parentId: '0',
        search: '',
        pageNum: 1,
        pageSize: 5
      },
      total: 10,
      bookmarkList: [],
      isFirstLevel: true,
      condition: '',
      loading: false,
      breadcrumb: [{
        id: 0,
        name: 'Receivers',
        selectedGrid: '',
        selectedName: ''
      }]
    }
  },
  components: {
    SearchInput,
    Pagination,
    BookmarkItem
  },
  created () {
    this.getBookMarkList({
      id: 0,
      selectedGrid: '',
      selectedName: ''
    })
  },
  methods: {
    backupStep (obj, index) {
      if (obj.id == this.params.parentId) return
      Bus.$emit('changeDevice', obj)
      this.getBookMarkList(this.breadcrumb[index], 'clear', index)
    },
    getBookMarkList (obj, tag, index) {
      this.loading = true
      if (obj) {
        this.params.pageNum = 1
        this.params.parentId = obj.id
        this.isFirstLevel = this.params.parentId == '0'
        if (tag && tag == 'clear') {
          const [...arr] = this.breadcrumb
          this.breadcrumb = arr.splice(0, index + 1)
        } else {
          if (!this.isFirstLevel) { this.breadcrumb.push(obj) }
        }
      }
      this.axios.post('/ccp/tvucc-user/userDevice/pageInfoBookMark', this.params, {
        contentType: 'application/json;charset=UTF-8'
      }).then(res => {
        setTimeout(() => {
          this.loading = false
        }, 100)
        if (res.data.errorCode == '0x0') {
          const result = res.data.result
          this.total = result.total
          this.bookmarkList = result.list
        } else {
          this.$message({
            type: 'info',
            message: res.data.errorInfo
          })
        }
      }).catch(() => {
        this.loading = false
      })
    },
    search (val) {
      this.params.search = val
      this.getBookMarkList()
      Bus.$emit('searchReceiver', {
        id: this.params.parentId,
        search: val
      })
    },
    // 分页
    handleCurrentChange (val) {
      this.params.pageNum = val
      this.getBookMarkList()
    },
    handleSizeChange (val) {
      this.params.pageSize = val
      this.params.pageNum = 1
      this.getBookMarkList()
    }
  }
}
</script>
<style lang='less' scoped>
.bookmark-container {
  text-align: left;
  height: 100%;
  .search-module {
    width: 300px;
    float: right;
    margin-bottom: 10px;
  }
  .breadcrumb {
    float: left;
    background: none;
    padding-left: 0;
    line-height: 32px;
    ::v-deep .el-breadcrumb__inner {
      color: #dbdbdb;
      cursor: pointer;
      font-size: 18px;
    }
  }
  .line {
    height: 2px;
    width: 100%;
    background: #444;
    clear: both;
  }
  .bookmark-box {
    height: 290px;
    padding: 10px 0;
    .title {
      display: inline-block;
      margin-top: 8px;
      font-size: 16px;
      color: #666;
    }
    .bookmark-list {
      margin-top: 10px;
      height: calc(100% - 40px);
      overflow-x: hidden;
    }
  }
}
</style>
